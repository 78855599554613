import { gql } from '@apollo/client';
import { PageInfoFragment } from './pagination.queries';

export const ISO_FRAGMENT = gql`
  fragment ISOFragment on ParadisoPlayerData {
    id
    slUid
    posFdg
    gp
    war
    warPct
    warRank
    warPer82
    warPer82Pct
    warPerActual82
    warPerActual82Pct
    gar
    penaltyDifferential
    penaltyDifferentialPct
    adjPenaltyDifferential
    adjPenaltyDifferentialXpg
    adjPenaltyDifferentialPct
    toi
    shotAttempts
    totalImpact
    weightedOffImpact
    weightedDefImpact
    weightedShotImpact
    dataByStrength {
      strength
      toi
      shotAttempts
      totalImpact
      totalImpactPct
      totalImpactRank
      offImpact
      offImpactPct
      weightedOffImpact
      defImpact
      defImpactPct
      weightedDefImpact
      shotImpact
      shotImpactPct
      weightedShotImpact
      goalieImpact
      goalieImpactPct
      goalieImpactRank
      shotsOnGoalAgainst
      weightedOffImpactPct
      weightedDefImpactPct
      weightedShotImpactPct
    }
  }
`;

export const WarSeasonFragment = gql`
  fragment warSeasonFragment on BDDWarBySeason {
    bddPlayerSlug
    bddLeagueSlug
    season
    pos
    age
    actualWar
    warGivenNhl
    probNhl
    expectedWar
    bddPlayer {
      slug 
      slUid
      fullName
      slPlayerMaster {
        defaultLeagueSlug
        slPlayers {
          firstname
          lastname
          leaguePlayerId
          leagueSlug
          teamSlug
        }
      }
    }
  }
`;

export const WarProjectionFragment = gql`
  fragment warProjectionFragment on BDDWarProjection {
    bddPlayerSlug
    bddPlayer {
      slug
      firstname
      lastname
    }
    neighbors
    metrics
    datetime
    seasons {
      season
      bddLeagueSlug
      pos
      age
      warGivenNhl
      probNhl
      expectedWar
      actualWar
      source
      details
      gp
      isMostCommonLeague
    }
  }
`;

export const WarProjectionsFragment = gql`
  fragment warProjectionsFragment on BDDWarProjection {
    bddPlayerSlug
    bddLeagueSlug
    pos
    age
    status
    warSeasonId
    currentWar
    metrics
    bddPlayer {
      slug
      slUid
      fullName
      slPlayerMaster {
        slPlayers {
          firstname
          lastname
          leaguePlayerId
          leagueSlug
          teamSlug
        }
      }
    }
  }
`;

export const GET_PLAYER_AVAILABLE_WAR_SPLITS = gql`
  query GetPlayerAvailableWarSplits($slUid: ID!) {
    playerAvailableWarSplits(slUid: $slUid) {
      id
      type
      numGames
      startSeason
      endSeason
      startDate
      endDate
      strengths
      playersCount
      etlRun {
        id
        league
        gameType
        datetime
      }
    }
  }
`;

export const GET_PLAYER_WAR = gql`
  query GetPlayerWar(
    $slUid: ID!
    $splitId: ID
    $splitType: String
    $numGames: Int
    $season: Int
    $startSeason: Int
    $endSeason: Int
  ) {
    playerIsolatedImpact(
      slUid: $slUid
      splitId: $splitId
      splitType: $splitType
      numGames: $numGames
      season: $season
      startSeason: $startSeason
      endSeason: $endSeason
    ) {
      ...ISOFragment
    }
  }
  ${ISO_FRAGMENT}
`;

export const LIST_PLAYERS_WAR = gql`
  query GetPlayerWar(
    $slUids: [ID!]
    $splitType: String
    $numGames: Int
    $season: Int
    $startSeason: Int
    $endSeason: Int
  ) {
    listPlayerIsolatedImpact(
      slUids: $slUids
      splitType: $splitType
      numGames: $numGames
      season: $season
      startSeason: $startSeason
      endSeason: $endSeason
    ) {
      ...ISOFragment
    }
  }
  ${ISO_FRAGMENT}
`;

export const GET_BDD_WAR_PROJECTION = gql`
  query GetBDDWarProjection($slug: ID!) {
    bddWarProjection(slug: $slug) {
      ...warProjectionFragment
    }
  }
  ${WarProjectionFragment}
`;

export const GET_PAGINATED_BDD_WAR_PROJECTIONS = gql`
  query GetPaginatedBDDWarProjections(
    $limit: Int
    $offset: Int
    $sort: [BDDSortV2]
    $filters: GenericScalar
    $metastatSplits: GenericScalar
  ) {
    paginatedBddWarProjections(
      filters: $filters
      limit: $limit
      offset: $offset
      sort: $sort
      metastatSplits: $metastatSplits
    ) {
      pageInfo {
        ...pageInfoFragment
      }
      data {
        ...warProjectionsFragment
      }
      extraData
    }
  }
  ${PageInfoFragment}
  ${WarProjectionsFragment}
`;

export const GET_PAGINATED_BDD_WAR_BY_SEASON = gql`
  query GetPaginatedBDDWarBySeason(
    $limit: Int
    $offset: Int
    $sort: [BDDSortV2]
    $filters: GenericScalar
    $metastatSplits: GenericScalar
  ) {
    paginatedBddWarBySeason(
      filters: $filters
      limit: $limit
      offset: $offset
      sort: $sort
      metastatSplits: $metastatSplits
    ) {
      pageInfo {
        ...pageInfoFragment
      }
      data {
        ...warSeasonFragment
      }
      extraData
    }
  }
  ${PageInfoFragment}
  ${WarSeasonFragment}
`;
