import { gql } from '@apollo/client';
import { PageInfoFragment } from './pagination.queries';
import { PlayerListFragment, PlayerListPlayersFragment } from './playerlist.queries';
import { BDDSigningFragment, BDDTradeFragment } from './bddtransactions.queries';
import { CFTeamSeasonSummaryFragment } from './capfriendly.queries';
import { BFCapHitFragment, BFPlayerBriefFragment, BFPlayerContractSeasonFragment, BFPlayerFragment, BFTeamDeadSpaceFragment, BFTeamFragment, BFTeamSeasonSummaryFragment } from './bruinsfriendly.queries';
import { BFPlayerContractFragment } from './contract.queries';
import { BDDCapSheetFragment, BDDDepthChartSpotWrapperFragment } from './depthchart.queries';

// export const ProObjectiveTransactionFragment = gql`
//   fragment proObjectiveTransactionsFragment on ProObjectiveTransactions {
//     id
//     proObjectiveSlug
//     transactionType
//     tradeId
//     signingId
//     bddSigning {
//       id
//       createdByUserId
//       createdBy { id name }
//       lastModified
//       createdAt
//       bddPlayer {
//         firstname
//         lastname
//       }
//       capHitStr
//       capHitNhl
//       capHitAhl
//       term
//     }
//     bddTrade {
//       id
//       createdByUserId
//       createdBy { id name }
//       lastModified
//       createdAt
//     }
//   }
// `;

export const TeamStrengthFragment = gql`
  fragment teamStrengthFragment on DepthChartTeamStrength {
    depthChartId
    targetTeamStrength {
      teamSlug
      winProb
      rank
      warFwd
      warDef
      gsaa
    }
    teamStrengths {
      teamSlug
      winProb
      rank
      warFwd
      warDef
      gsaa
    }
  }
`;


export const BDDDepthCheckFragment = gql`
  fragment bddDepthCheckFragment on BDDDepthCheck {
    slug
    name
    description
    threshold
    isChecked
    thresholds
    payload
  }
`;

export const ProObjectiveTargetsFragment = gql`
  fragment proObjectiveTargetsFragment on ProObjectiveTargets {
    id
    proObjectiveSlug
    priority
    payload
    positions
    primaryPosition
    isActive
    isAsset
    status
    assetType
    assetId
    bddPlayerSlug
    bfDraftPickId
    teamSlug
    interest
    availability
    acquisitionCost
    fit
    ahlDepth
    wtp
    currentAsk
    isReclamation
    slTeam {
      slug
      shorthand
      longname
      nhlid
    }
    teamLinks {
      id
      teamSlug
      slTeam {
        id
        slug
        longname
        shorthand
        nhlid
      }
      linkType
    }
    bddSignings {
      id
      capHitStr
      capHitNhl
      capHitAhl
      term
    }
    bddPlayer {
      id
      slug
      bfSlug
      firstname
      lastname
      availability
      contractStatus
      age
      bfPlayer {
        slug
        capHit
        aav
        currentRemainingTerm
        expiryStatus
        futureRemainingTerm
        futureCapHit
        retainedCapHit
        retainedPct
        retainedDetails
        hasPastContract
        hasActiveContract
        hasFutureContract
      }
      roleTag {
        tagId
        flags
        isProjection
        tag {
          id
          tag
          tagType
          description
          color
          textColor
          dateCreated
          isBddTag
          requiresPosition
          priority
        }
      }
      bddManagedTags {
        id
        tagId
        flags
        isProjection
        tag {
          id
          tag
          tagType
          description
          color
          textColor
          dateCreated
          isBddTag
          requiresPosition
          priority
        }
      }
      rinknetPlayer {
        id
        realId
        eliteprospectsid
        nhlid
        firstname
        lastname
        currentteam
        currentteamid
        currentteamnhlid
        currentleague
        currentleagueabbr
        jerseynumber
        age
        birthdate
        heightStr
        weight
        hometown
        nationality
        position
        posFDG
        handedness
        round
        overall
        draftyear
        collegecommitmentteam
        shotside
        agent {
          id
          title
          firstname
          lastname
          companyname
          email
          phone
          cellphone
        }
      }
    }
  }
`;

export const ProObjectiveFragment = gql`
  fragment proObjectiveFragment on ProObjectives {
    id
    slug
    name
    description
    teamSlug
    createdBy {
      id
      name
    }
    createdAt
    lastModifiedBy {
      id
      name
    }
    lastModified
    canEdit
    isPublic
    isPublicEdit
    accessList {
      userId
      user {
        id
        name
      }
      canEdit
    }
    pinPriority
    bddDepthChartUniqueKey
    depthChart {
      id
      teamSlug
      active
    }
    slTeam {
      slug
      longname
      shorthand
      nhlid
    }
  }
`;

const ProObjectiveChangeFragment = gql`
  fragment proObjectiveChangeFragment on ProObjectiveChanges {
    id
    proObjectiveSlug
    userId
    changeType
    entityType
    entityId
    dateCreated
    payload
    target {
      id
      bddPlayerSlug
      bddPlayer {
        slug
        firstname
        lastname
      }
    }
    user {
      id
      name
    }
  }
`;

const ProObjectiveListFragment = gql`
  fragment proObjectiveListFragment on ProObjectiveLists {
    id
    proObjectiveSlug
    listId
    objectiveListType
    pinPriority
  }
`;

const ProObjectiveTargetDividerFragment = gql`
  fragment proObjectiveTargetDividerFragment on ProObjectiveTargetDividers {
    id
    proObjectiveSlug
    groupBy
    group
    isAfter
    title
    description
    targetId
    priority
  }
`;

export const ProObjectiveGroupersFragment = gql`
  fragment proObjectiveGroupersFragment on ProObjectiveGroupers {
    id
    proObjectiveSlug
    label
    description
    groups {
      id
      grouperId
      name
      priority
      targetLinks {
        id
        targetId
      }
    }
  }
`;

export const ProObjectiveTeamsFragment = gql`
  fragment proObjectiveTeamsFragment on ProObjectiveTeams {
    teamSlug
    payload
    isStarred
    slTeam {
      id
      slug
      longname
      shorthand
      nhlid
    }
  }
`;

export const ProObjectiveScenarioFragment = gql`
  fragment proObjectiveScenarioFragment on ProObjectiveScenario {
    id
    proObjectiveSlug
    uniqueKey
    title
    description
    depthChartId
    payload
    priority
    userId
    createdAt
    lastModified
    isPollScenario
    teamSlug
    season
    baseDepthChartUniqueKey
    canEdit
    cacheCapBreakdownTimestamp
    transactions {
      transactionId
      order
      variantId
    }
    user {
      id
      name
      username
    }
  }
`;

export const ProObjectiveTransactionFragment = gql`
  fragment proObjectiveTransactionFragment on ProObjectiveTransactions {
    id
    proObjectiveSlug
    transactionType
    title
    description
    createdAt
    createdByUserId
    lastModified
  }
`;

export const ProObjectiveBoardPresetsFragment = gql`
  fragment proObjectiveBoardPresetsFragment on ProObjectiveBoardPresets {
    id
    label
    description
    store
    priority
  }
`;

export const GET_PRO_OBJECTIVES = gql`
  query GetProObjectives($pinsOnly: Boolean) {
    proObjectives(pinsOnly: $pinsOnly) {
      ...proObjectiveFragment
    }
  }
  ${ProObjectiveFragment}
`;

export const GET_PRO_OBJECTIVE = gql`
  query GetProObjective($slug: ID!, $includeTeamStrength: Boolean, $cacheOnly: Boolean, $skipCache: Boolean) {
    proObjective(slug: $slug, includeTeamStrength: $includeTeamStrength, cacheOnly: $cacheOnly, skipCache: $skipCache) {
      ...proObjectiveFragment
      teamStrength { 
        ...teamStrengthFragment
      }
    }
  }
  ${ProObjectiveFragment}
  ${TeamStrengthFragment}
`;

export const GET_PRO_OBJECTIVE_TARGETS = gql`
  query GetProObjectiveTargets($slug: ID!) {
    proObjectiveTargets(slug: $slug) {
      ...proObjectiveTargetsFragment
    }
    proObjective(slug: $slug) {
      slug
      lastModified
    }
    proObjectiveTargetDividers(proObjectiveSlug: $slug) {
      ...proObjectiveTargetDividerFragment
    }
    proObjectiveTargetGroupers(proObjectiveSlug: $slug) {
      ...proObjectiveGroupersFragment
    }
  }
  ${ProObjectiveTargetsFragment}
  ${ProObjectiveTargetDividerFragment}
  ${ProObjectiveGroupersFragment}
`;

export const buildProObjectiveListsQuery = ({ includePlayers = false }) => gql`
  query GetProObjectiveLists(
    $proObjectiveSlug: ID!
    $includePlayers: Boolean
  ) {
    proObjectiveLists(proObjectiveSlug: $proObjectiveSlug includePlayers: $includePlayers) {
      ...proObjectiveListFragment
      playerList {
        ...playerListFragment
        ${
          includePlayers
            ? `players {
            ...playerListPlayersFragment
          }`
            : ''
        }
      }
    }
  }
  ${ProObjectiveListFragment}
  ${PlayerListFragment}
  ${includePlayers && PlayerListPlayersFragment}
`;

export const GET_PRO_OBJECTIVE_TRANSACTIONS = gql`
  query GetProObjectiveTransactions($slug: ID!) {
    proObjectiveTransactions(slug: $slug) {
      ...proObjectiveTransactionFragment
      bddTrades {
        ...bddTradeFragment
      }
      bddSignings {
        ...bddSigningFragment
      }
    }
  }
  ${ProObjectiveTransactionFragment}
  ${BDDTradeFragment}
  ${BDDSigningFragment}
`;


export const GET_PRO_OBJECTIVE_BOARD_PRESETS = gql`
  query GetProObjectiveBoardPresets($slug: ID!) {
    proObjectiveBoardPresets(slug: $slug) {
      ...proObjectiveBoardPresetsFragment
    }
  }
  ${ProObjectiveBoardPresetsFragment}
`;

export const GET_PRO_OBJECTIVE_TEAMS = gql`
  query GetProObjectiveTeams($slug: ID!) {
    proObjectiveTeams(slug: $slug) {
      ...proObjectiveTeamsFragment
      targets {
        id
      }
      interest {
        id
      }
      bfTeamSeasonSummary {
        ...bfTeamSeasonSummaryFragment
      }
    }
  }
  ${ProObjectiveTeamsFragment}
  ${BFTeamSeasonSummaryFragment}
`;

export const UPDATE_PRO_OBJECTIVE_TEAM = gql`
  mutation UpdateProObjectiveTeam(
    $proObjectiveSlug: ID!
    $teamSlug: ID!
    $input: GenericScalar!
  ) {
    updateProObjectiveTeam(
      proObjectiveSlug: $proObjectiveSlug
      teamSlug: $teamSlug
      input: $input
    ) {
      proObjectiveTeam {
        ...proObjectiveTeamsFragment
        targets {
          id
        }
        interest {
          id
        }
        bfTeamSeasonSummary {
          ...bfTeamSeasonSummaryFragment
        }
      }
    }
  }
  ${ProObjectiveTeamsFragment}
  ${BFTeamSeasonSummaryFragment}
`;

export const GET_PRO_OBJECTIVE_SCENARIOS = gql`
  query GetProObjectiveScenarios(
    $proObjectiveSlug: ID
    $uniqueKey: ID
    $ignoreUniqueKeys: Boolean
    $scenarioIdFilter: [ID]
    $includeCapSheet: Boolean
    $includeCapBreakdown: Boolean
    $includeChecks: Boolean
    $includeTeamStrength: Boolean
    $skipCache: Boolean
    $cacheOnly: Boolean
  ) {
    proObjectiveScenarios(
      proObjectiveSlug: $proObjectiveSlug
      uniqueKey: $uniqueKey
      ignoreUniqueKeys: $ignoreUniqueKeys
      scenarioIdFilter: $scenarioIdFilter
      includeCapSheet: $includeCapSheet
      includeCapBreakdown: $includeCapBreakdown
      includeChecks: $includeChecks
      includeTeamStrength: $includeTeamStrength
      skipCache: $skipCache
      cacheOnly: $cacheOnly
    ) {
      ...proObjectiveScenarioFragment
      capSheet {
        ...bddCapSheetFragment
      }
      depthChecks {
        checks {
          ...bddDepthCheckFragment
        }
        skatersData
        goaliesData
      }
      teamStrength {
        ...teamStrengthFragment
      }
      depthChart {
        id
        lastModified
        depthChartPlayers {
          id
          playerSlug
          lineNum
          position
          lineupSpot
          depthLeague
          bddPlayer {
            id
            slug
            firstname
            lastname
            bfPlayer {
              slug
              capHit
              expiryStatus
              currentRemainingTerm
              futureRemainingTerm
            }
          }
        }
      }
    }
  }
  ${ProObjectiveScenarioFragment}
  ${BDDCapSheetFragment}
  ${BFTeamSeasonSummaryFragment}
  ${BDDDepthCheckFragment}
  ${TeamStrengthFragment}
`;

export const GET_SCENARIO = gql`
  query GetScenario(
    $id: ID!
    $includeCapSheet: Boolean
    $includeCapBreakdown: Boolean
    $includeChecks: Boolean
    $includeTeamStrength: Boolean
    $skipCache: Boolean
    $cacheOnly: Boolean
  ) {
    proObjectiveScenario(
      id: $id
      includeCapSheet: $includeCapSheet
      includeCapBreakdown: $includeCapBreakdown
      includeChecks: $includeChecks
      includeTeamStrength: $includeTeamStrength
      skipCache: $skipCache
      cacheOnly: $cacheOnly
    ) {
      ...proObjectiveScenarioFragment
      transactions {
        transactionId
        order
        variantId
        transaction {
          ...proObjectiveTransactionFragment
          bddTrades {
            ...bddTradeFragment
          }
          bddSignings {
            ...bddSigningFragment
          }
        }
      }
      capSheet {
        ...bddCapSheetFragment
      }
      depthChecks {
        checks {
          ...bddDepthCheckFragment
        }
        skatersData
        goaliesData
      }
      teamStrength {
        ...teamStrengthFragment
      }
      depthChart {
        id
        lastModified
        depthChartPlayers {
          id
          playerSlug
          lineNum
          position
          lineupSpot
          depthLeague
          bddPlayer {
            id
            slug
            firstname
            lastname
            bfPlayer {
              capHit
              expiryStatus
              currentRemainingTerm
              futureRemainingTerm
            }
          }
        }
      }
    }
  }
  ${ProObjectiveScenarioFragment}
  ${ProObjectiveTransactionFragment}
  ${BDDTradeFragment}
  ${BDDSigningFragment}
  ${BDDCapSheetFragment}
  ${BFTeamSeasonSummaryFragment}
  ${BDDDepthCheckFragment}
  ${TeamStrengthFragment}
`;

export const GET_SCENARIO_CAP_BREAKDOWN = gql`
  query GetScenarioCapBreakdown($id: ID!) {
    scenarioCapBreakdown(id: $id) {
      lastModified
      bfTeam {
        ...BFTeamFragment
      }
      teamSummary {
        ...bfTeamSeasonSummaryFragment
      }
      deadSpace {
        ...bfTeamDeadSpaceFragment
        source
      }
      bfPlayers {
        ...bfPlayerBriefFragment
        source
        bddPlayer {
          slug
        }
        freeAgentSeason {
          season
          expiryStatus
          expiryStatusDetails
        }
        activeAndFutureContracts {
          ...bfPlayerContractFragment
        }
        allContractSeasons {
          ...bfPlayerContractSeasonsFragment
        }
        currentCapHit {
          ...bfCapHitFragment
        }
      }
      wrappers {
        ...bddDepthChartSpotWrapperFragment
      }
    }
  }
  ${BFTeamFragment}
  ${BFTeamSeasonSummaryFragment}
  ${BFPlayerBriefFragment}
  ${BFPlayerContractFragment}
  ${BFPlayerContractSeasonFragment}
  ${BFCapHitFragment}
  ${BFTeamDeadSpaceFragment}
  ${BDDDepthChartSpotWrapperFragment}
`;

export const GET_PRO_OBJECTIVE_CHANGES = gql`
  query GetProObjectiveChanges(
    $proObjectiveSlug: ID!
    $limit: Int
    $offset: Int
    $startDate: Date
    $endDate: Date
    $changeTypes: [POChangeTypes]
    $userIds: [ID]
  ) {
    proObjectiveChanges(
      proObjectiveSlug: $proObjectiveSlug
      limit: $limit
      offset: $offset
      startDate: $startDate
      endDate: $endDate
      changeTypes: $changeTypes
      userIds: $userIds
    ) {
      data {
        ...proObjectiveChangeFragment
      }
      pageInfo {
        ...pageInfoFragment
      }
    }
  }
  ${ProObjectiveChangeFragment}
  ${PageInfoFragment}
`;

export const CREATE_PRO_OBJECTIVE = gql`
  mutation CreateProObjective(
    $input: CreateProObjectiveInput!
    $accessList: [ObjectiveUserAccessInput]
  ) {
    createProObjective(input: $input, accessList: $accessList) {
      proObjective {
        ...proObjectiveFragment
      }
    }
  }
  ${ProObjectiveFragment}
`;

export const UPDATE_PRO_OBJECTIVE = gql`
  mutation UpdateProObjective(
    $input: UpdateProObjectiveInput!
    $accessToOverwrite: [ObjectiveUserAccessInput]
    $accessToRemove: [ID]
  ) {
    updateProObjective(
      input: $input
      accessToOverwrite: $accessToOverwrite
      accessToRemove: $accessToRemove
    ) {
      proObjective {
        ...proObjectiveFragment
      }
    }
  }
  ${ProObjectiveFragment}
`;

export const CREATE_PRO_OBJECTIVE_TARGETS = gql`
  mutation CreateProObjectiveTargets(
    $objectiveSlug: ID!
    $targets: [CreateProObjectiveTargetInput!]!
  ) {
    createProObjectiveTargets(
      objectiveSlug: $objectiveSlug
      targets: $targets
    ) {
      targets {
        ...proObjectiveTargetsFragment
      }
    }
  }
  ${ProObjectiveTargetsFragment}
`;

export const UPDATE_PRO_OBJECTIVE_TARGET = gql`
  mutation UpdateProObjectiveTarget(
    $objectiveSlug: ID!
    $input: UpdateProObjectiveTargetInput!
    $overwriteTeamLinks: [TeamTargetLinkInput]
    $removeTeamLinks: [ID]
  ) {
    updateProObjectiveTarget(
      objectiveSlug: $objectiveSlug
      input: $input
      overwriteTeamLinks: $overwriteTeamLinks
      removeTeamLinks: $removeTeamLinks
    ) {
      target {
        ...proObjectiveTargetsFragment
      }
    }
  }
  ${ProObjectiveTargetsFragment}
`;

export const DELETE_PRO_OBJECTIVE_TARGET = gql`
  mutation DeleteProObjectiveTarget($objectiveSlug: ID!, $id: ID!) {
    deleteProObjectiveTarget(objectiveSlug: $objectiveSlug, id: $id) {
      id
    }
  }
`;

export const UPDATE_PRO_OBJECTIVE_TARGET_PRIORITIES = gql`
  mutation UpdateProObjectiveTargetPriorities(
    $objectiveSlug: ID!
    $orderedTargetIds: [ID]
    $targetMoves: [POTargetMove]
    $lastModified: DateTime
  ) {
    updateProObjectiveTargetPriorities(
      objectiveSlug: $objectiveSlug
      orderedTargetIds: $orderedTargetIds
      targetMoves: $targetMoves
      lastModified: $lastModified
    ) {
      targets {
        id
        priority
      }
    }
  }
`;

export const SYNC_PRO_OBJECTIVE_TARGETS_TO_TIERED_LIST = gql`
  mutation SyncProObjectiveTargetsToTieredList(
    $proObjectiveSlug: ID!
    $listId: ID!
    $updatePriorities: Boolean
    $updatePrioritiesInGroups: Boolean
  ) {
    syncProObjectiveTargetsToTieredList(
      proObjectiveSlug: $proObjectiveSlug
      listId: $listId
      updatePriorities: $updatePriorities
      updatePrioritiesInGroups: $updatePrioritiesInGroups
    ) {
      proObjectiveGrouper {
        ...proObjectiveGroupersFragment
      }
    }
  }
  ${ProObjectiveGroupersFragment}
`;

export const OVERWRITE_PRO_OBJECTIVE_BOARD_PRESETS = gql`
  mutation OverwriteProObjectiveBoardPresets($input: POBoardPresetInput!) {
    overwriteProObjectiveBoardPreset(input: $input) {
      proObjectiveBoardPreset {
        ...proObjectiveBoardPresetsFragment
      }
    }
  }
  ${ProObjectiveBoardPresetsFragment}
`;

export const DELETE_PRO_OBJECTIVE_BOARD_PRESET = gql`
  mutation DeleteProObjectiveBoardPreset($id: ID!) {
    deleteProObjectiveBoardPreset(id: $id) {
      id
    }
  }
`;

export const CREATE_PRO_OBJECTIVE_TARGET_DIVIDER = gql`
  mutation CreateProObjectiveTargetDivider(
    $input: CreatePOTargetDividerInput!
  ) {
    createProObjectiveTargetDivider(input: $input) {
      divider {
        ...proObjectiveTargetDividerFragment
      }
    }
  }
  ${ProObjectiveTargetDividerFragment}
`;

export const UPDATE_PRO_OBJECTIVE_TARGET_DIVIDER = gql`
  mutation UpdateProObjectiveTargetDivider(
    $input: UpdatePOTargetDividerInput!
  ) {
    updateProObjectiveTargetDivider(input: $input) {
      divider {
        ...proObjectiveTargetDividerFragment
      }
    }
  }
  ${ProObjectiveTargetDividerFragment}
`;

export const DELETE_PRO_OBJECTIVE_TARGET_DIVIDER = gql`
  mutation DeleteProObjectiveTargetDivider($id: ID!) {
    deleteProObjectiveTargetDivider(id: $id) {
      id
    }
  }
`;

export const CREATE_PRO_OBJECTIVE_SCENARIO = gql`
  mutation CreateProObjectiveScenario(
    $input: CreatePOScenarioInput!
    $useEmptyDepthChart: Boolean
    $copyFromScenarioId: ID
  ) {
    createProObjectiveScenario(
      input: $input
      useEmptyDepthChart: $useEmptyDepthChart
      copyFromScenarioId: $copyFromScenarioId
    ) {
      scenario {
        ...proObjectiveScenarioFragment
      }
    }
  }
  ${ProObjectiveScenarioFragment}
`;

export const UPDATE_PRO_OBJECTIVE_SCENARIO = gql`
  mutation UpdateProObjectiveScenario(
    $input: UpdatePOScenarioInput!
    $playerStatusesToSet: [ScenarioPlayerStatusInput]
    $deadSpaceToAdd: BFTeamDeadSpaceInput
    $deadSpaceToRemove: ID
    $contractToBuyout: ID
    $transactionsToAttach: [POScenarioTransactionInput]
    $transactionsToRemove: [ID]
  ) {
    updateProObjectiveScenario(
      input: $input
      playerStatusesToSet: $playerStatusesToSet
      deadSpaceToAdd: $deadSpaceToAdd
      deadSpaceToRemove: $deadSpaceToRemove
      contractToBuyout: $contractToBuyout
      transactionsToAttach: $transactionsToAttach
      transactionsToRemove: $transactionsToRemove
    ) {
      scenario {
        capSheet {
          ...bddCapSheetFragment
        }
        ...proObjectiveScenarioFragment
      }
    }
  }
  ${ProObjectiveScenarioFragment}
  ${BDDCapSheetFragment}
  ${BFTeamSeasonSummaryFragment}
`;

export const DELETE_PRO_OBJECTIVE_SCENARIO = gql`
  mutation DeleteProObjectiveScenario($id: ID!) {
    deleteProObjectiveScenario(id: $id) {
      id
    }
  }
`;

export const CREATE_UPDATE_PRO_OBJECTIVE_LIST = gql`
  mutation CreateUpdateProObjectiveList($input: ProObjectiveListInput!) {
    createUpdateProObjectiveList(input: $input) {
      proObjectiveList {
        ...proObjectiveListFragment
      }
    }
  }
  ${ProObjectiveListFragment}
`;

export const DELETE_PRO_OBJECTIVE_LIST = gql`
  mutation DeleteProObjectiveList($proObjectiveSlug: ID!, $listId: ID!) {
    deleteProObjectiveList(proObjectiveSlug: $proObjectiveSlug, listId: $listId) {
      proObjectiveSlug
      listId
    }
  }
`;

export const REORDER_PRO_OBJECTIVE_LISTS = gql`
  mutation ReorderProObjectiveLists($proObjectiveSlug: ID!, $orderedListIds: [ID]) {
    reorderProObjectiveLists(
      proObjectiveSlug: $proObjectiveSlug
      orderedListIds: $orderedListIds
    ) {
      proObjectiveLists {
        id
        proObjectiveSlug
        listId
        pinPriority
      }
    }
  }
`;

export const CREATE_PRO_OBJECTIVE_TRANSACTION = gql`
  mutation CreateProObjectiveTransaction(
    $proObjectiveSlug: ID
    $transactionType: POTransactionType!
    $trade: BDDTradeInput
    $signing: BDDSigningInput
  ) {
    createProObjectiveTransaction(
      proObjectiveSlug: $proObjectiveSlug
      transactionType: $transactionType
      trade: $trade
      signing: $signing
    ) {
      proObjectiveTransaction {
        ...proObjectiveTransactionFragment
        bddTrades {
          ...bddTradeFragment
        }
        bddSignings {
          ...bddSigningFragment
        }
      }
    }
  }
  ${ProObjectiveTransactionFragment}
  ${BDDSigningFragment}
  ${BDDTradeFragment}
`;

export const ADD_PRO_OBJECTIVE_TRANSACTION_VARIANT = gql`
  mutation AddProObjectiveTransactionVariant(
    $proObjectiveSlug: ID
    $transactionId: ID!
    $trade: BDDTradeInput
    $signing: BDDSigningInput
  ) {
    addProObjectiveTransactionVariant(
      proObjectiveSlug: $proObjectiveSlug
      transactionId: $transactionId
      trade: $trade
      signing: $signing
    ) {
      proObjectiveTransaction {
        ...proObjectiveTransactionFragment
        bddTrades {
          ...bddTradeFragment
        }
        bddSignings {
          ...bddSigningFragment
        }
      }
    }
  }
  ${ProObjectiveTransactionFragment}
  ${BDDSigningFragment}
  ${BDDTradeFragment}
`;

export const EDIT_PRO_OBJECTIVE_TRANSACTION = gql`
  mutation EditProObjectiveTransaction(
    $proObjectiveSlug: ID
    $input: EditPOTransactionInput!
    $variantsToRemove: [ID]
  ) {
    editProObjectiveTransaction(
      proObjectiveSlug: $proObjectiveSlug
      input: $input
      variantsToRemove: $variantsToRemove
    ) {
      proObjectiveTransaction {
        ...proObjectiveTransactionFragment
        bddTrades {
          ...bddTradeFragment
        }
        bddSignings {
          ...bddSigningFragment
        }
      }
    }
  }
  ${ProObjectiveTransactionFragment}
  ${BDDSigningFragment}
  ${BDDTradeFragment}
`;

export const REMOVE_PRO_OBJECTIVE_TRANSACTION = gql`
  mutation CreateProObjectiveTransaction(
    $id: ID
    $proObjectiveSlug: ID!
    $force: Boolean
  ) {
    removeProObjectiveTransaction(
      id: $id
      proObjectiveSlug: $proObjectiveSlug
      force: $force
    ) {
      id
    }
  }
`;

export const CREATE_PRO_OBJECTIVE_GROUPER = gql`
  mutation CreateProObjectiveGrouper(
    $objectiveSlug: ID!
    $input: CreateProObjectiveGrouperInput!
  ) {
    createProObjectiveGrouper(objectiveSlug: $objectiveSlug, input: $input) {
      proObjectiveGrouper {
        ...proObjectiveGroupersFragment
      }
    }
  }
  ${ProObjectiveGroupersFragment}
`;

export const UPDATE_PRO_OBJECTIVE_GROUPER = gql`
  mutation UpdateProObjectiveGrouper(
    $objectiveSlug: ID!
    $input: UpdateProObjectiveGrouperInput!
    $groupsToAdd: [POGroupInput]
    $groupsToRemove: [ID]
    $setGroupPriorities: [ID]
    $targetsToGroup: [GroupTargetInput]
  ) {
    updateProObjectiveGrouper(
      objectiveSlug: $objectiveSlug
      input: $input
      groupsToAdd: $groupsToAdd
      groupsToRemove: $groupsToRemove
      setGroupPriorities: $setGroupPriorities
      targetsToGroup: $targetsToGroup
    ) {
      proObjectiveGrouper {
        ...proObjectiveGroupersFragment
      }
    }
  }
  ${ProObjectiveGroupersFragment}
`;

export const DELETE_PRO_OBJECTIVE_GROUPER = gql`
  mutation DeleteProObjectiveGrouper($objectiveSlug: ID!, $id: ID!) {
    deleteProObjectiveGrouper(objectiveSlug: $objectiveSlug, id: $id) {
      id
    }
  }
`;

export const UPDATE_PRO_OBJECTIVE_GROUP = gql`
  mutation UpdateProObjectiveGroup($input: UpdatePOGroupInput!) {
    updateProObjectiveGroup(input: $input) {
      proObjectiveGroup {
        id
        grouperId
        name
        priority
        targetLinks {
          id
          targetId
        }
      }
    }
  }
`;


const ScenarioUniqueKeysFragment = gql`
  fragment scenarioUniqueKeysFragment on ScenarioUniqueKeys {
    id
    key
    name
    description
    pinPriority
    isArchived
    defaults
  }
`

export const GET_SCENARIO_UNIQUE_KEYS = gql`
  query GetScenarioUniqueKeys($pinsOnly: Boolean, $includeArchived: Boolean) {
    scenarioUniqueKeys(pinsOnly: $pinsOnly, includeArchived: $includeArchived) {
      ...scenarioUniqueKeysFragment
    }
  }
  ${ScenarioUniqueKeysFragment}
`;

export const CREATE_SCENARIO_UNIQUE_KEY = gql`
  mutation CreateScenarioUniqueKey($input: ScenarioUniqueKeyInput!) {
    createScenarioUniqueKey(input: $input) {
      scenarioUniqueKey {
        ...scenarioUniqueKeysFragment
      }
    }
  }
  ${ScenarioUniqueKeysFragment}
  `

export const UPDATE_SCENARIO_UNIQUE_KEY = gql`
  mutation UpdateScenarioUniqueKey($input: UpdateScenarioUniqueKeyInput!) {
    updateScenarioUniqueKey(input: $input) {
      scenarioUniqueKey {
        ...scenarioUniqueKeysFragment
      }
    }
  }
  ${ScenarioUniqueKeysFragment}
`

export const DELETE_SCENARIO_UNIQUE_KEY = gql`
  mutation DeleteScenarioUniqueKey($key: ID!) {
    deleteScenarioUniqueKey(key: $key) {
      key
    }
  }
`

export const GET_OR_CREATE_TEAM_SCENARIO_BY_KEY = gql`
  query GetOrCreateTeamScenarioByKey($teamSlug: ID!, $uniqueKey: String!) {
    getOrCreateTeamScenarioByKey(teamSlug: $teamSlug, uniqueKey: $uniqueKey) {
      ...proObjectiveScenarioFragment
    }
  }
  ${ProObjectiveScenarioFragment}
`