import { useQuery } from '@apollo/client';
import {
  GET_BF_BUYOUT_CALCULATOR,
  GET_BF_CONTRACT,
  GET_BF_TEAMS,
} from 'apollo/queries/bruinsfriendly.queries';
import { IconButton } from 'components/bdd/Button';
import { WindowContext } from 'components/bdd/context';
import { Divider } from 'components/bdd/Divider';
import Icon from 'components/bdd/Icon';
import { Column, Container, Row } from 'components/bdd/Layout';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { Typography } from 'components/bdd/Typography';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import PlayerLinkHover from 'components/PlayersV2/Hover/PlayerLinkHover';
import { TooltipSpan } from 'components/reports';
import { theme } from 'constants';
import { formatDollars } from 'helpers/contracts';
import { roundToX } from 'helpers/data';
import {
  getCurrentSeason,
  getNextOrCurrentSeason,
  getSeasonPlusMinusYears,
  season8to4,
  seasonToStartYear,
} from 'helpers/hockeyutils';
import { getNHLTeamLogoUrl } from 'helpers/logos';
import { useContext, useEffect, useState } from 'react';
import { ArrowLeft, X } from 'react-bootstrap-icons';
import styled from 'styled-components';

const textColor = 'rgb(197, 195, 193)';

const StyledTable = styled.table({
  width: '100%',
  th: {
    ...theme.typography.body1,
    minWidth: 40,
    backgroundColor: 'rgb(30, 63, 101)',
    color: 'white',
  },
  td: {
    ...theme.typography.body2,
  },
  // striped table
  'tr:nth-child(even)': {
    td: {
      backgroundColor: 'rgb(47, 45, 44)',
    },
  },
  'td,th': {
    padding: '2px 4px',
    whiteSpace: 'nowrap',
  },
});

export const BFPlayerContractBuyout = ({
  contractId,
  defaultYear,
  onYearChange,
  onBack,
}) => {
  const { data, placeholder } = usePlaceholder(
    useQuery(GET_BF_CONTRACT, {
      variables: { id: contractId },
    })
  );
  const bfContract = data?.bfContract;
  const bfPlayer = bfContract?.bfPlayer;
  const yearOptions = bfContract?.contractSeasons.map((s) => ({
    label: (
      <Typography color={textColor}>{season8to4(s.season, true)}</Typography>
    ),
    value: seasonToStartYear(s.season),
  }));

  const [year, setYear] = useState(defaultYear);

  useEffect(() => {
    setYear(defaultYear);
  }, [defaultYear]);

  useEffect(() => {
    if (!year && !!yearOptions) {
      const nextSeason = getSeasonPlusMinusYears(getCurrentSeason(), 1);
      if (!!yearOptions.find((y) => y.value == seasonToStartYear(nextSeason))) {
        setYear(seasonToStartYear(nextSeason));
      } else {
        setYear(seasonToStartYear(yearOptions[0].value));
      }
    }
  }, [yearOptions]);

  const {
    data: buyoutData,
    placeholder: buyoutPlaceholder,
    error: buyoutError,
  } = useQuery(GET_BF_BUYOUT_CALCULATOR, {
    variables: { contractId, buyoutYear: year },
    skip: !contractId || !year,
  });

  const buyouts = buyoutData?.bfBuyoutCalculator;
  // const { width } = useContext(WindowContext);
  // const mathVariant = width < theme.breakpoints.md ? 'h6' : 'h5';

  if (!!placeholder || !!buyoutPlaceholder)
    return placeholder || buyoutPlaceholder;
  if (!!buyoutError)
    return <Typography variant="body1">Error calculating buyout</Typography>;
  if (!buyoutData) return <Typography variant="body1">Loading...</Typography>;

  return (
    <Container
      padding={4}
      width={'100%'}
      backgroundColor={'rgb(37, 35, 34)'}
      style={{ color: textColor }}
    >
      <Column gap={4}>
        {buyouts.map((buyout) => {
          const fields = [
            {
              label: 'Base Salary Remaining',
              value: formatDollars(buyout.remainingBaseSalary, {
                truncate: false,
              }),
              tooltip:
                'Sum of base salary from each remaining year at time of buyout',
            },
            {
              label: 'S. Bonus Remaining',
              value: formatDollars(buyout.remainingSigningBonus, {
                truncate: false,
              }),
              tooltip:
                'Sum of signing bonus from each remaining year at time of buyout',
            },
            {
              label: 'Years Remaining',
              value: buyout.remainingTerm,
              tooltip: 'Remaining years left on contract at time of buyout',
            },
            {
              label: 'Buyout Length',
              value: buyout.buyoutLength,
              tooltip:
                'Number of years over which buyout would be paid.  This is twice the remaining term.',
            },
            {
              label: 'Age',
              value: roundToX(buyout.playerAge, 1),
              tooltip: 'Age at time of buyout',
            },
            {
              label: 'Buyout Mult.',
              value: `${roundToX(100 * buyout.buyoutMultiplier, 1)}%`,
              tooltip:
                'The age at the time of the buyout determines the buyout amount. For players that are 26 or older, a buyout is 2/3 of the remaining salary owed on the contract. For players that are under 26, a buyout is 1/3 of the remaining salary owed on the contract',
            },
            {
              label: 'Total Cost',
              value: formatDollars(buyout.totalCost, { truncate: false }),
              tooltip:
                'The total cost of the buyout is the total remaining base salary times the buyout multiplier',
            },
            {
              label: 'Total Savings',
              value: formatDollars(buyout.totalSavings, { truncate: false }),
              tooltip:
                'The total savings is the difference between the remaining base salary and the total buyout cost',
            },
            {
              label: 'Annual Cost',
              value: formatDollars(buyout.annualCost, { truncate: false }),
              tooltip:
                'The annual cost of the buyout is the total cost divided by the buyout term',
            },
          ];
          return (
            <Container minWidth="100%">
              <Column gap={2}>
                {!buyout.teamSlug && (
                  <Container>
                    <Row gap={16} justifyContent="center">
                      {!!onBack && (
                        <IconButton
                          color={textColor}
                          icon={<ArrowLeft />}
                          onClick={onBack}
                          tooltip="Back to contract"
                        />
                      )}
                      <Row gap={4}>
                        <PlayerLinkHover
                          name={`${bfPlayer.firstName.toUpperCase()} ${bfPlayer.lastName.toUpperCase()}`}
                          slug={bfPlayer.bddPlayer?.slug}
                          variant="h5"
                          style={{ textDecoration: 'underline' }}
                          color={textColor}
                        />
                        <Typography variant="h5">BUYOUT RESULTS</Typography>
                      </Row>
                    </Row>
                    <Row gap={4}>
                      <Typography variant="body2">
                        Assuming a buy out before the start of
                      </Typography>
                      <StyledBDDSelect
                        collapseIndicator
                        variant="outlined"
                        selectedValue={year}
                        options={yearOptions}
                        onChange={(n, v) => {
                          setYear(v);
                          if (!!onYearChange) onYearChange(v);
                        }}
                      />
                    </Row>
                  </Container>
                )}
                {buyouts.length > 1 && (
                  <TeamRetainedHeader
                    buyout={buyout}
                    teamSlug={buyout.teamSlug || bfPlayer.teamSlug}
                  />
                )}
                <Container>
                  <Column gap={1}>
                    {/* Evan don't like my math */}
                    {/* Dan thinks it's okay */}
                    {/* <Container>
                <Row gap={32} justifyContent='space-around'>
                  <Row gap={4} alignItems='start'>
                    <Column gap={0} alignItems='end'>
                      <Typography variant={mathVariant}>
                        {buyout.remainingTerm}
                      </Typography>
                      <Typography variant='stat'>
                        Remaining Term
                      </Typography>
                    </Column>
                    <Row>
                      <Icon icon={<X/>} size={20}/>
                      <Typography variant={mathVariant}>2</Typography>
                    </Row>
                    <Typography variant={mathVariant}>=</Typography>
                    <Column gap={0} alignItems='start'>
                      <Typography variant={mathVariant}>{buyout.buyoutLength}</Typography>
                      <Typography variant='stat'>Buyout Term</Typography>
                    </Column>
                  </Row>                

                  <Row gap={8} alignItems='start'>
                    <Column gap={0} alignItems='end'>
                      <Typography variant={mathVariant}>
                        {formatDollars(buyout.remainingBaseSalary, { truncate: false })}
                      </Typography>
                      <Typography variant='stat'>
                        Remaining Base Salary
                      </Typography>
                    </Column>
                    <Icon icon={<X/>} size={20}/>
                    <Column alignItems='center'>
                      <Typography variant={mathVariant}>{roundToX(100*buyout.buyoutMultiplier, 1)}%</Typography>
                      <TooltipSpan content={'The age at the time of the buyout determines the buyout amount. For players that are 26 or older, a buyout is 2/3 of the remaining salary owed on the contract. For players that are under 26, a buyout is 1/3 of the remaining salary owed on the contract.'}>
                        <Typography variant='stat' style={{ textDecoration: 'underline dotted'}}>
                          Buyout Multiplier
                        </Typography>
                      </TooltipSpan>
                    </Column>
                    <Typography variant={mathVariant}>=</Typography>
                    <Column gap={0} alignItems='start'>
                      <Typography variant={mathVariant}>{formatDollars(buyout.totalCost, { truncate: false })}</Typography>
                      <Typography variant='stat'>Total Buyout Cost</Typography>
                    </Column>
                  </Row>

                  <Row gap={8} alignItems='start'>
                    <Column gap={0} alignItems='end'>
                      <Typography variant={mathVariant}>
                        {formatDollars(buyout.totalCost, { truncate: false })}
                      </Typography>
                      <Typography variant='stat'>
                        Total Buyout Cost
                      </Typography>
                    </Column>
                    <Typography variant={mathVariant}>/</Typography>
                    <Column alignItems='center'>
                      <Typography variant={mathVariant}>{buyout.buyoutLength}</Typography>
                      <Typography variant='stat' style={{ textDecoration: 'underline dotted'}}>
                        Buyout Term
                      </Typography>
                    </Column>
                    <Typography variant={mathVariant}>=</Typography>
                    <Column gap={0} alignItems='start'>
                      <Typography variant={mathVariant}>{formatDollars(buyout.annualCost, { truncate: false })}</Typography>
                      <Typography variant='stat'>Annual Buyout Cost</Typography>
                    </Column>
                  </Row>
                </Row>
              </Container> */}

                    <Divider />

                    <Container>
                      <StyledTable>
                        <thead>
                          <tr>
                            {fields.map((f) => (
                              <th key={f.label}>{f.label}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {fields.map((f) => (
                              <td key={f.label}>{f.value}</td>
                            ))}
                          </tr>
                        </tbody>
                      </StyledTable>
                    </Container>
                  </Column>
                </Container>
                <Divider />
                <Container>
                  <BuyoutTable bfPlayer={bfPlayer} seasons={buyout.seasons} />
                </Container>
              </Column>
            </Container>
          );
        })}
        <Container>
          <Column gap={0}>
            <Typography variant="body2">
              The final cap hit of each year is calculated as:
            </Typography>

            <Container>
              <Row gap={4}>
                <Typography variant="stat">Final Cap Hit</Typography>
                <Typography variant="stat">=</Typography>
                <Typography variant="stat">Original Cap Hit</Typography>
                <Typography variant="stat">-</Typography>
                <Typography variant="stat">Base Salary</Typography>
                <Typography variant="body3">+</Typography>
                <Typography variant="stat">Annual Buyout Cost</Typography>
              </Row>
            </Container>
          </Column>
        </Container>
      </Column>
    </Container>
  );
};

const BuyoutTable = ({ bfPlayer, seasons }) => {
  const keys = [
    { id: 'baseSalary', label: 'Initial Base Salary' },
    { id: 'originalCapHit', label: 'Initial Cap Hit' },
    { id: 'signingBonus', label: 'Signing Bonus' },
    { id: 'annualBuyoutCost', label: 'Buyout Cost' },
    { id: 'totalSalary', label: 'Total Salary' },
    { id: 'savings', label: 'Savings', highlight: true },
    { id: 'finalCapHit', label: 'Cap Hit', style: { borderTop: '1px solid #ddd' } },
  ];
  return (
    <Column gap={1}>
      <Container width={'100%'} overflowX={'auto'}>
        <StyledTable>
          <thead>
            <tr>
              <th>Season</th>
              {keys.map((k) => (
                <th key={k.id}>{k.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {seasons.map((s) => (
              <tr key={s.season}>
                <td>{season8to4(s.season, true)}</td>
                {keys.map((k) => (
                  <td
                    key={k.id}
                    style={{
                      background: !k.highlight
                        ? undefined
                        : `${
                            s[k.id] < 0
                              ? theme.colors.states.danger
                              : theme.colors.states.success
                          }22`,
                      color: !k.highlight
                        ? undefined
                        : s[k.id] < 0
                        ? theme.colors.states.danger
                        : theme.colors.states.success,
                    }}
                  >
                    {formatDollars(s[k.id], { truncate: false })}
                  </td>
                ))}
              </tr>
            ))}
            <tr></tr>
          </tbody>
        </StyledTable>
      </Container>

      <Divider />
    </Column>
  );
};

const TeamRetainedHeader = ({ buyout, teamSlug }) => {
  const { data } = useQuery(GET_BF_TEAMS, {
    variables: {
      isActive: true,
    },
  });

  if (!data) return null;

  const bfTeam = data?.bfTeams.find((t) => t.slug == teamSlug);

  console.log(bfTeam);
  return (
    <Container>
      <Row columnGap={2}>
        <img alt="" height={20} src={getNHLTeamLogoUrl(bfTeam.nhlid)} />
        <Typography variant="body1">{bfTeam.name} retains {buyout.retainedPercent}%</Typography>
      </Row>
    </Container>
  );
};
