import React, { useContext, useState } from 'react';

import { ButtonGroup } from './ButtonGroup';
import { LinkButtonGroup } from './LinkButtonGroup';
import { useBreakpoints } from '../context';

export const useButtonGroup = ({
  options,
  initialSelectedValue,
  variant, // link or default
  width,
  noWrap,
  disabled = false,
  onClick: onClickArg, // called with the option
  noInitialSelectedValue = false,
  typographyVariant = 'body2',
  hideCaptionOnOptionDisplay,
}) => {
  const [selectedValue, setSelectedValue] = useState(initialSelectedValue);

  if (!options || options.length === 0) {
    return {
      selectedOption: null,
      buttonGroup: null,
    };
  }

  const selectedOption =
    options
      .flatMap((o) => (o.options || []).concat(o))
      .find((option) => option.value === selectedValue) ||
    (!noInitialSelectedValue && options[0]) ||
    null;

  if (disabled) {
    options = options.map((o) => ({ ...o, disabled: true }));
  }

  const onClick = (option) => {
    setSelectedValue(option.value);
    if (!!onClickArg) onClickArg(option);
  };

  return {
    selectedOption,
    selectedValue,
    forceSelectOption: setSelectedValue,
    buttonGroup:
      variant === 'link' ? (
        <LinkButtonGroup
          options={options}
          onClick={onClick}
          selectedValue={selectedOption?.value}
          width={width}
          noWrap={noWrap}
        />
      ) : (
        <ButtonGroup
          options={options}
          onClick={onClick}
          selectedValue={selectedOption?.value}
          width={width}
          noWrap={noWrap}
          variant={typographyVariant}
          hideCaptionOnOptionDisplay={hideCaptionOnOptionDisplay}
        />
      ),
  };
};
