import React from 'react';
import styled from 'styled-components';
import { TooltipSpan } from '../reports';
import { theme } from 'constants';

const Styles = styled.div`
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  ${(props) =>
    props.noBorder
      ? ''
      : props.isProjection
      ? `
        border: 1px dashed ${props.color};
        // box-shadow: 0 0 1px ${props.background};
        `
      : `border: 1px solid ${theme.colors.light.border};`}

  font-size: 10px;
  font-weight: bold;
  border-radius: 10px;
  padding: ${(props) => (props.noLabel ? '4px' : '3px')};
  margin: 0px 2px;
  overflow: hidden;
  white-space: nowrap;
  padding-left: ${(props) => (props.noLabel ? '4px' : '8px')};
  padding-right: ${(props) => (props.noLabel ? '4px' : '8px')};
`;
export default function BDDTag({
  tag,
  isProjection = false,
  noBorder = false,
  noLabel = false,
}) {
  return (
    <BDDTagTooltip tag={tag} isProjection={isProjection}>
      <Styles
        background={getTagBackgroundColor(tag.color, isProjection)}
        color={tag.textColor || 'black'}
        noBorder={noBorder}
        noLabel={noLabel}
      >
        {noLabel ? '' : tag.tag}
      </Styles>
    </BDDTagTooltip>
  );
}

export const BDDTagTooltip = ({ tag, children, isProjection = false }) => {
  return (
    <TooltipSpan
      placement={'bottom'}
      content={
        (!!tag.description || isProjection) && (
          <>
            {tag.description}
            {isProjection ? (
              <em>
                <small> (projected)</small>
              </em>
            ) : (
              ''
            )}
          </>
        )
      }
    >
      {children}
    </TooltipSpan>
  );
};

export const getTagBackgroundColor = (color, isProjection) => {
  return isProjection
    ? `repeating-linear-gradient(
            -45deg,
            ${color},
            ${color} 5px,
            #eee 5px,
            #eee 6px
        )`
    : color;
};
