import { HoverInfo } from 'components/bdd';
import { Border, Column, Container, Grid, GridArea, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { TooltipSpan } from 'components/reports';
import { theme } from 'constants';
import { formatDollars } from 'helpers/contracts';
import { roundToX } from 'helpers/data';
import { formatDate } from 'helpers/helpers';
import { season8to4 } from 'helpers/hockeyutils';
import { getNHLTeamLogoUrl } from 'helpers/logos';
import styled from 'styled-components';
import { BFTwoWayTag } from './tags/BFTwoWayTag';
import { BFPerformanceBonusTag } from './tags/BFPerformanceBonusTag';
import { BFThirtyFivePlusTag } from './tags/BFThirtyFivePlusTag';
import { BFNTCTag } from './tags/BFNMCTag';
import { BFTerminatedTag } from './tags/BFTerminatedTag';
import { BFBoughtOutTag } from './tags/BFBoughtOutTag';
import { BFWaiverTag } from './tags/BFWaiverTag';
import { BFGroupSixTag } from './tags/BFGroupSixTag';
import { useContext, useState } from 'react';
import { WindowContext } from 'components/bdd/context';
import { BFElcTag } from './tags/BFELCTag';
import { IconButton, LinkButton } from 'components/bdd/Button';
import Icon from 'components/bdd/Icon';
import { ArrowLeft, Cash, ExclamationOctagon } from 'react-bootstrap-icons';
import { BFPlayerContractBuyout } from './BFPlayerContractBuyout';

const parseSigningStatus = (contract) => {
  let signingStatus = contract.signingStatus;

  if (contract.wasNonQo) {
    signingStatus = `${signingStatus} (Non QO)`;
  }

  if (contract.wasGroup6) {
    signingStatus = `${signingStatus} (Group 6)`;
  }

  if (contract.was102c) {
    signingStatus = `${signingStatus} (10.2(c))`;
  }

  return signingStatus;
};

export default function BFPlayerContractBreakdown({ bfPlayer, contract }) {
  const [mode, setMode] = useState('contract'); // contract or buyout
  if (!contract) return <Typography variant="error">No contract</Typography>;
  const signingTeam = contract?.signingTeam;

  const { width } = useContext(WindowContext);
  const headerWidth = width <= theme.breakpoints.xs ? 175 : 200;

  const headFields = [
    [
      {
        label: '',
        value: (
          <Row columnGap={3}>
            <Typography variant="body1" noWrap>
              {`${season8to4(contract.startSeason, true)} to ${season8to4(
                contract.endSeason,
                true
              )}`}
            </Typography>
            <Row>
              <BFGroupSixTag contract={contract} />
              <BFElcTag contract={contract} />
              <BFPerformanceBonusTag contract={contract} />
              <BFThirtyFivePlusTag contract={contract} />
              <BFNTCTag contract={contract} />
              <BFTerminatedTag contract={contract} />
              <BFBoughtOutTag contract={contract} />
            </Row>
          </Row>
        ),
      },
      { label: 'Length', value: `${contract.numYears} years` },
    ],
    // [
    //   { label: 'Length', value: `${contract.numYears} years` },
    //   {
    //     label: 'Signed with',
    //     value: (
    //       <Container>
    //         <img alt="" height="20px" src={getNHLTeamLogoUrl(signingTeam.nhlid)} />
    //         {signingTeam.name}
    //       </Container>
    //     ),
    //   },
    // ],
    [
      {
        label: 'Signed with',
        value: (
          <Container>
            <img
              alt=""
              height="20px"
              src={getNHLTeamLogoUrl(signingTeam.nhlid)}
            />
            {signingTeam.name}
          </Container>
        ),
      },
      {
        label: 'Signing Date',
        value: formatDate(contract.signedDate, { format: '%b %d, %Y' }),
      },
    ],
    [
      { label: 'Signing Status', value: parseSigningStatus(contract) },
      { label: 'Signing Age', value: roundToX(contract.signingAge, 1) },
      // { label: 'Expiry Age', value: roundToX(contract.expirationAge, 1) },
    ],
    [
      { label: 'Expiry Status', value: contract.expiryStatus },
      {
        label: 'Expiry Details',
        value: !!contract.expiryStatusDetails && (
          <TooltipSpan content={contract.expiryStatusDetails}>
            {contract.expiryStatusDetails
              ?.split(/(?=[A-Z])/)
              ?.join(' ')
              ?.slice(0, 50)}
            {contract.expiryStatusDetails?.length > 50 && '...'}
          </TooltipSpan>
        ),
      },
    ],
    [
      {
        label: 'Total Value',
        value: formatDollars(contract.totalSalary, { truncate: false }),
      },
      {
        label: 'AAV',
        value: formatDollars(contract.aav, { truncate: false }),
      },
    ],
  ];

  return (
    <Border>
      <Container padding={2}>
        {mode === 'contract' ? (
          <Column gap={1}>
            <Grid
              columnGap={2}
              gridTemplateColumns={`repeat(auto-fill, minmax(${headerWidth}px, 1fr))`}
            >
              {headFields.map((g, index) => (
                <Container key={index}>
                  {g.map((f, index) => (
                    <Container key={f.label || index}>
                      <Row columnGap={1}>
                        {f.label && (
                          <Typography variant="body1">{f.label}</Typography>
                        )}
                        <Typography variant="body2">{f.value}</Typography>
                      </Row>
                    </Container>
                  ))}
                </Container>
              ))}
            </Grid>
            <Container padding={1} overflow="auto">
              <ContractTable
                contractSeasons={contract.contractSeasons}
                elcSlideYears={contract.entryLevelSlideYears}
                performanceBonusDetails={contract.performanceBonusDetails}
                hasTradeClause={contract.hasTradeClause}
              />
            </Container>
            <Container>
              <LinkButton onClick={() => setMode('buyout')}>
                <Row gap={4}>
                  <Icon icon={<Cash />} />
                  <Typography variant="body2">Buyout this contract</Typography>
                </Row>
              </LinkButton>
            </Container>
          </Column>
        ) : (
          <Container>
            <BFPlayerContractBuyout
              contractId={contract.id}
              onBack={() => setMode('contract')}
            />
          </Container>
        )}
      </Container>
    </Border>
  );
}

const StyledTable = styled.table({
  width: '100%',
  overflowX: 'auto',
  // tableLayout: 'fixed',

  th: {
    ...theme.typography.body1,
    borderBottom: '1px solid #ddd',
  },
  td: {
    ...theme.typography.body2,
  },
  'tr:nth-child(odd)': {
    td: {
      backgroundColor: '#f2f2f2',
    },
  },
});
const ContractTable = ({
  contractSeasons,
  elcSlideYears,
  performanceBonusDetails,
  hasTradeClause,
}) => {
  const sumReducer = (acc, curr) => acc + curr;
  const totalsRow = {
    isTotal: true,
    capHit: contractSeasons.map((cs) => cs.capHit).reduce(sumReducer, 0),
    totalSalary: contractSeasons
      .map((cs) => cs.totalSalary)
      .reduce(sumReducer, 0),
    baseSalary: contractSeasons
      .map((cs) => cs.baseSalary)
      .reduce(sumReducer, 0),
    signingBonus: contractSeasons
      .map((cs) => cs.signingBonus)
      .reduce(sumReducer, 0),
    performanceBonus: contractSeasons
      .map((cs) => cs.performanceBonus)
      .reduce(sumReducer, 0),
    guaranteedSalary: contractSeasons
      .map((cs) => cs.guaranteedSalary)
      .reduce(sumReducer, 0),
    minorSalary: contractSeasons
      .map((cs) => cs.minorSalary)
      .reduce(sumReducer, 0),
  };

  const rows =
    contractSeasons.length > 1
      ? [...contractSeasons, totalsRow]
      : contractSeasons;

  return (
    <StyledTable>
      <thead>
        <tr>
          <th>Season</th>
          <th>Cap Hit</th>
          {!!hasTradeClause && (
            <th>
              <TooltipSpan
                content={
                  <Typography variant="caption">
                    Warning: information in this column is based on data parsed
                    from contracts and may contain errors. You can validate the
                    exact legal text by hovering over the icon:
                    <Icon icon={<ExclamationOctagon />} />
                  </Typography>
                }
              >
                NMC/NTC
              </TooltipSpan>
            </th>
          )}
          <th style={{ whiteSpace: 'nowrap' }}>% of Cap</th>
          <th>
            <TooltipSpan content="Total Salary">Tot.</TooltipSpan>
          </th>
          <th>
            <TooltipSpan content="Base Salary a player receives when assigned in the NHL.  Does not include signing bonuses.">
              Base
            </TooltipSpan>
          </th>
          <th>
            <TooltipSpan content="Amount player receives as a signing bonus">
              Sign.
            </TooltipSpan>
          </th>
          <th>
            <TooltipSpan content="The maximum performance bonuses a player can receive in each season">
              Perf
            </TooltipSpan>{' '}
            {!!performanceBonusDetails && (
              <HoverInfo content={performanceBonusDetails} />
            )}
          </th>
          <th>
            <TooltipSpan content="Salary paid if player is in the minors">
              Minors
            </TooltipSpan>
          </th>
          <th>
            <TooltipSpan content="Guaranteed salary paid ">Guar.</TooltipSpan>
          </th>
        </tr>
      </thead>
      <tbody>
        {rows.map((s, i) => {
          const slid = !!elcSlideYears && i < elcSlideYears;
          return (
            <tr key={s.season || i}>
              <td style={{ minWidth: 50 }}>
                {s.isTotal ? <b>Total</b> : season8to4(s.season, true)}
              </td>
              <td style={{ minWidth: 75 }}>
                {formatDollars(s.capHit, { truncate: false })}
              </td>
              {!!hasTradeClause && (
                <td>
                  <TooltipSpan content={s.clauseDetails}>
                    {s.nmc ? 'NMC' : s.ntc ? 'NTC' : null}
                  </TooltipSpan>
                </td>
              )}
              <td style={{ minWidth: 50 }}>
                {!!s.capPct && `${roundToX(s.capPct, 1)}%`}
              </td>
              <td colSpan={slid ? 2 : 1} style={{ minWidth: 75 }}>
                {!!slid
                  ? 'ENTRY-LEVEL SLIDE'
                  : formatDollars(s.totalSalary, { truncate: false })}
              </td>
              {!slid && (
                <td style={{ minWidth: 75 }}>
                  {formatDollars(s.baseSalary, { truncate: false })}
                </td>
              )}
              <td style={{ minWidth: 75 }}>
                {formatDollars(s.signingBonus, { truncate: false })}
              </td>
              <td style={{ minWidth: 75 }}>
                {!!slid
                  ? '-'
                  : formatDollars(s.performanceBonus, { truncate: false })}
              </td>
              <td style={{ minWidth: 75 }}>
                {formatDollars(s.minorSalary, { truncate: false })}
              </td>
              <td style={{ minWidth: 75 }}>
                {formatDollars(s.guaranteedSalary, { truncate: false })}
              </td>
            </tr>
          );
        })}
      </tbody>
    </StyledTable>
  );
};
