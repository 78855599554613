import Icon from 'components/bdd/Icon';
import { TooltipSpan } from 'components/reports';
import {
  ArrowDownCircleFill,
  ArrowRightSquareFill,
  Bandaid,
  BandaidFill,
  Cash,
  PiggyBank,
} from 'react-bootstrap-icons';

export const WaiverExemptIcon = ({ showTooltip = true, size = '15px' }) => {
  return (
    <TooltipSpan
      content={
        showTooltip && 'Player does not require waivers to be assigned to the minors'
      }
    >
      <Icon icon={<ArrowDownCircleFill />} color="green" size={size} />
    </TooltipSpan>
  );
};

export const IRIcon = ({ showTooltip = true, size = '15px' }) => {
  return (
    <TooltipSpan content={showTooltip && 'Player is on the injured reserve list'}>
      <Icon icon={<Bandaid />} color="red" size={size} />
    </TooltipSpan>
  );
};

export const LTIRIcon = ({ showTooltip = true, size = '15px' }) => {
  return (
    <TooltipSpan
      content={showTooltip && 'Player is on the long term injured reserve list'}
    >
      <Icon icon={<BandaidFill />} color="red" size={size} />
    </TooltipSpan>
  );
};

export const RetainedIcon = ({ showTooltip = true, size = '15px' }) => {
  return (
    <TooltipSpan content={showTooltip && 'Player has salary retained'}>
      <Icon icon={<PiggyBank />} color="black" size={size} />
    </TooltipSpan>
  );
};

export const ExtendedIcon = ({ showTooltip = true, size = '15px' }) => {
  return <Icon icon={<ArrowRightSquareFill />} color="green" size={size} />;
};

export const PerfBonusIcon = ({ showTooltip = true, size = '15px', color = 'black' }) => {
  return (
    <TooltipSpan content={showTooltip && 'Player has performance bonus'}>
      <Icon icon={<Cash />} color={color} size={size} />
    </TooltipSpan>
  );
};
