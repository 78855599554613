import BDDStatTableV2 from 'components/bdd/Stats/BDDStatTableV2';
import useToggle from 'components/bdd/Toggle/useToggle';
import { Column, Row } from 'components/bdd/Layout';
import {
  SLStatContext,
  SLStatTrendPlot,
  StatCellVideoLinks,
  StatScatterChart,
} from '.';
import { useContext } from 'react';
import { StyledTable } from 'components/bdd/Table';

const SL_TOURNAMENT_LEAGUE_SLUGS = [
  'WJC',
  'HG',
  'WJSS',
  'CTC',
  'IIHFU18',
  'WH18',
  'WH19',
  'IIHF',
  'WC',
  'WJC17',
  'CHLUSA',
  '5NAT',
  '5NATU17',
];

export const SLStatTable = ({
  data,
  indexColumns,
  toggleControls,
  highlightCallback,
  cellHoverCallback: cellHoverCallbackArg,
  cellDecorationCallback,
  selectCallback,
  hidePlayoffsToggle,
  defaultShowPlayoffs = false,
  hideTournamentsToggle,
  defaultShowTournaments = false,
  ...rest
}) => {
  const { chartOptions, target } = useContext(SLStatContext);

  const hasTournaments = data?.data.some((d) =>
    SL_TOURNAMENT_LEAGUE_SLUGS.includes(d.league_slug)
  );

  const { toggled: showTournaments, toggleComponent: tournamentsToggle } =
    useToggle({
      label: `Show tournaments`,
      initialToggled: defaultShowPlayoffs,
      disabled: !hasTournaments,
    });

  // Is there any nhl playoffs data
  const hasPlayoffs =
    data?.data.filter((d) => d.game_type === 'nhl_postseason').length > 0;

  const { toggled: showPlayoffs, toggleComponent: playoffsToggle } = useToggle({
    label: `Show playoffs`,
    initialToggled: defaultShowPlayoffs,
    disabled: !hasPlayoffs,
  });

  // Filter out preseason data
  let filteredData = data?.data.filter(
    (d) =>
      d.game_type !== 'nhl_preseason' &&
      (showPlayoffs || d.game_type !== 'nhl_postseason') &&
      (showTournaments || !SL_TOURNAMENT_LEAGUE_SLUGS.includes(d.league_slug))
  );

  const cellHoverCallback =
    cellHoverCallbackArg ||
    ((label, row, statDef, forceClose) => {
      return (
        <StatCellVideoLinks
          onClick={forceClose}
          statDefinition={statDef}
          statRow={row}
        />
      );
    });

  switch (chartOptions.type) {
    case 'scatter':
      return <StatScatterChart />;
    case 'trend':
      return <SLStatTrendPlot target={target} />;
    case 'table':
    default:
      return (
        <StyledTable variant="highlight" withBorders>
          <Column gap={2}>
            <Row columnGap={4}>
              {!hidePlayoffsToggle && playoffsToggle}
              {!hideTournamentsToggle && tournamentsToggle}
              {toggleControls}
            </Row>
            <BDDStatTableV2
              data={filteredData}
              format={data?.format}
              indexColumns={indexColumns}
              highlight={true}
              highlightCallback={highlightCallback}
              cellDecorationCallback={cellDecorationCallback}
              cellHoverCallback={cellHoverCallback}
              hoverProps={{ delay: { show: 400, hide: 100 } }}
              styledTable
              {...rest}
            />
          </Column>
        </StyledTable>
      );
  }
};
