import BDDPlotly from 'components/bdd/bddplotly';
import { Typography } from 'components/bdd/Typography';
import { theme } from 'constants';
import { roundToX, smoothData } from 'helpers/data';
import { getTeamColorPalette } from 'helpers/plotting';
import { useContext, useState } from 'react';
import { SLStatContext } from '.';
import { searchMetastatsSplitsForStat } from 'helpers/stats';
import { season8to4 } from 'helpers/hockeyutils';

const xPeriod = {
  year: 'M12',
  month: 'M1',
};

const getDateKey = (date, period) => {
  switch (period) {
    case 'year':
      return `${date.getFullYear()}-1-1`;
    case 'month':
      return `${date.getFullYear()}-${date.getMonth() + 1}-1`;
    case 'day':
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    default:
      throw Error('Unknown period:', period);
  }
};
const getSeasonTeamKey = (season, teamSlug) => `${season}-${teamSlug}`;

export const SLStatTrendPlot = ({ target = 'skaters', posFDG }) => {
  const { data, placeholder, filters, chartOptions } =
    useContext(SLStatContext);

  const {
    options: {
      yAxisStat,
      showStandardRange,
      showLeagueAvg,
      invertYAxis,
      compareToPosition,
      compareToTeam,
      windowSize,
    },
  } = chartOptions || {};

  const findMetastats = (statLabel, row) => {
    // if team_slug not in row, must compare to league
    const targetGroup =
      target === 'teams'
        ? 'league'
        : compareToTeam && 'team_slug' in row
        ? row.team_slug
        : 'league';

    const rowPos =
      posFDG === 'G'
        ? 'g'
        : target === 'teams'
        ? 'all'
        : !!compareToPosition
        ? !!row.position?.toLowerCase()
          ? row.position.toLowerCase()
          : 'all'
        : 'all';

    const statDef = data?.format?.stat_definitions.find(
      (sd) => sd.label === statLabel
    );
    return searchMetastatsSplitsForStat(data?.extras?.metastats, statDef, row, {
      targetGroup,
      targetPosition: rowPos,
      matchOn: 'slug_stat_filter_id',
    });
  };

  if (!data || !data.data?.length) return placeholder;
  if (!yAxisStat) return null;

  const statLabel = yAxisStat;
  const seasons = filters.season.length;
  const selectedPeriod = seasons > 1 ? 'month' : 'day';

  // Group data by season/team and then by date-period (days or months)
  // groupedData looks like: { '20222023-nhl.bos': [{dateKey: '2022-1-1', date: '2022-1-1', games: [...]}, {...}] }
  const groupedData = [...data.data]
    .sort((a, b) => (b.game_date > a.game_date ? 1 : -1))
    .reduce((grouped, game) => {
      const seasonTeamKey = getSeasonTeamKey(game.season, game.team_slug);
      const existingSTGroup = grouped.find((r) => r.key === seasonTeamKey);

      let seasonTeamGroup = existingSTGroup;
      if (!existingSTGroup) {
        seasonTeamGroup = {
          key: seasonTeamKey,
          ...game,
          groupedDates: [],
        };
        grouped.push(seasonTeamGroup);
      }

      const date = new Date(game.game_date);
      const key = getDateKey(date, selectedPeriod); // key is a date, but pulled down to start of month/year
      const existingGroup = seasonTeamGroup.groupedDates.find(
        (r) => r.dateKey == key
      );

      if (existingGroup) {
        existingGroup.games.push(game);
      } else {
        seasonTeamGroup.groupedDates.push({
          dateKey: key,
          date: new Date(key),
          games: [game],
        });
      }

      return grouped;
    }, []);

  const pdata = [];
  const shapes = [];
  groupedData.forEach((ts) => {
    // team/season data
    // find xrange, using grouped date keys
    const dates = ts.groupedDates.map((gd) => gd.date);
    const xrange = [Math.min(...dates), Math.max(...dates)];

    const scatter = {
      name: `${season8to4(ts.season, true)} ${ts.team_slug} ${statLabel}`,
      type: selectedPeriod === 'day' ? 'scatter' : 'bar',
      mode: 'markers',
      x: [],
      y: [],
      customdata: [],
      hovertemplate: '%{customdata}',
      marker: {
        color: getTeamColorPalette(ts.team_slug)[0],
        opacity: 0.7,
        line: {
          color: theme.colors.light.text.primary,
          width: 1,
        },
      },
      ...(xPeriod[selectedPeriod] && {
        xperiod: xPeriod[selectedPeriod],
        xperiodalignment: 'start',
      }),
    };

    // Fill x, y of scatter
    ts.groupedDates.forEach((gd) => {
      // grouped date data
      scatter.x.push(gd.date);

      const value = gd.games.reduce((mean, game) => {
        return mean + game[statLabel] / gd.games.length;
      }, 0);
      scatter.y.push(value);

      scatter.customdata.push(
        gd.games
          .map(
            (g) =>
              `${g.game_date} ${g.team_shorthand} vs. ${g.opp_team_shorthand} 
          <b>${statLabel}:</b> ${roundToX(g[statLabel], 2)}`
          )
          .join('<br>')
      );
    });

    // Smoothed trend
    let smoothY = smoothData(scatter.y, {
      windowSize: windowSize,
      windowType: 'box',
      center: true,
      padding: 'mean',
    });
    const smoothed = {
      name: 'Smoothed',
      x: scatter.x,
      y: smoothY,
      type: 'scatter',
      mode: 'lines',
      hoverinfo: 'skip',
      marker: { color: getTeamColorPalette(ts.team_slug)[1] },
    };

    // metastats
    const [split, metastats] = findMetastats(statLabel, ts);
    const metastatsBox = !!metastats
      ? {
          type: 'rect',
          x0: xrange[0],
          y0: metastats.mean - metastats.stddev,
          x1: xrange[1],
          y1: metastats.mean + metastats.stddev,
          fillcolor: '#333',
          opacity: 0.1,
          line: {
            width: 0,
          },
        }
      : null;

    const metastatsLine = !!metastats
      ? {
          x: xrange,
          y: [metastats.mean, metastats.mean],
          mode: 'lines',
          type: 'scatter',
          name: `${ts.team_shorthand} average`,
          opacity: 0.3,
          line: {
            dash: 'dot',
            color: getTeamColorPalette(ts.team_slug)[1],
          },
          hoverinfo: 'skip',
        }
      : null;

    pdata.push(scatter);
    pdata.push(smoothed);
    if (!!metastats) {
      if (showLeagueAvg) {
        pdata.push(metastatsLine);
      }
      if (showStandardRange) {
        shapes.push(metastatsBox);
      }
    }
  });

  return (
    <div
      style={{
        marginTop: '10px',
        paddingTop: '10px',
        borderTop: '1px solid #333',
      }}
    >
      <Typography style={{ textAlign: 'center' }} variant="h6">
        {statLabel} by game
      </Typography>
      <BDDPlotly
        data={pdata}
        layout={{
          shapes,
          hovermode: 'closest',
          margin: { t: 50 },
          yaxis: {
            title: statLabel,
          },
          showlegend: true,
          legend: {
            orientation: 'h',
            x: 0.5,
            xanchor: 'center',
            yanchor: 'bottom',
            y: 1,
          },
        }}
      />
    </div>
  );
};
