import { useQuery } from '@apollo/client';
import { GET_SCOUTING_RANKINGS_FOR_PLAYER } from 'apollo/queries/scouting.queries';
import BDDPlotly from 'components/bdd/bddplotly';
import { Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { roundToX } from 'helpers/data';
import { nameToInitials } from 'helpers/hockeyutils';
import { bruinsGold } from 'helpers/plotting';
import { useMemo } from 'react';
import { ScoutingRankSplitDescription } from './ScoutingGrades';
import { useButtonGroup } from 'components/bdd/ButtonGroup';
import BDDSortableTable from 'components/bdd/bddsortabletable';
import BDDErrorBoundary from 'components/bdd/bdderrorboundary';
import { Cell, buildColumns } from 'components/bdd/Table';
import { getRedGreenColor } from 'helpers/tables';

export default function ReportsByScout({ rinknetId, scoutId: scoutIdArg }) {
  const { data: rankingsData, placeholder } = usePlaceholder(
    useQuery(GET_SCOUTING_RANKINGS_FOR_PLAYER, {
      variables: { rinknetId, useDefaultSplit: true, byScout: true},
    })
  );

  const { buttonGroup, selectedOption } = useButtonGroup({
    options: [
      {
        value: 'chart',
        label: 'Chart',
      },
      {
        value: 'table',
        label: 'Table',
      },
    ],
  });

  const rp = rankingsData?.scoutingRankingsForPlayer?.rinknetPlayer;
  const posFDG = rp?.posFDG?.toLowerCase();

  const scoutToRankings = useMemo(() => {
    if (!rankingsData) return {};
    const isDraftEligible = rp.isdrafteligible ? true : null;
    return rankingsData.scoutingRankingsForPlayer.rankings
      .filter(
        (r) =>
          r.split.isDraftEligible === isDraftEligible &&
          r.split.position === posFDG.toLowerCase() &&
          !!r.split.scoutId
      )
      .reduce((acc, curr) => {
        if (!(curr.split.scoutId in acc)) acc[curr.split.scoutId] = [];
        acc[curr.split.scoutId].push(curr);
        return acc;
      }, {});
  }, [rankingsData]);

  if (!rankingsData) return placeholder;

  return (
    <Container>
      <Row justifyContent="space-between">
        <Container>
          <Typography variant="body1">
            Ranked against other {rp.isdrafteligible ? 'draft eligible' : ''}{' '}
            {posFDG.toUpperCase()} reports from the past 2 years
          </Typography>
        </Container>
        <Container>{buttonGroup}</Container>
      </Row>
      <BDDErrorBoundary>
        {selectedOption.value == 'chart' && (
          <ReportSkillsByScoutChart scoutToRankings={scoutToRankings} />
        )}
        {selectedOption.value == 'table' && (
          <ReportSkillsByScoutTable scoutToRankings={scoutToRankings} />
        )}
      </BDDErrorBoundary>
    </Container>
  );
}

const ReportSkillsByScoutChart = ({ scoutToRankings }) => {
  const scoutIds = Object.keys(scoutToRankings);

  const pdata = scoutIds.map((s) => ({
    mode: 'markers+text',
    type: 'scatter',
    name: `${scoutToRankings[s][0].split.scoutName} (${scoutToRankings[s][0].count} views)`,
    x: scoutToRankings[s].map((r) => 100 * (1 - r.percentile)),
    y: scoutToRankings[s].map((r) => r.rating.shortName),
    text: scoutToRankings[s].map((r) => `<b>${nameToInitials(r.split.scoutName)}</b>`),
    hovertemplate: '%{customdata}',
    customdata: scoutToRankings[s].map(
      (r) =>
        `<b>${r.split.scoutName}</b><br>` +
        `${roundToX(100 * (1 - r.percentile), 1)}% ${r.rating.shortName}<br>` +
        `${r.count} views<br>` +
        `<extra></extra>`
    ),
    marker: {
      size: 25,
      opacity: 0.5,
      color: bruinsGold,
    },
  }));

  const layout = {
    hovermode: 'closest',
    showlegend: true,
    legend: {
      orientation: 'h',
      x: 0.5,
      y: 1,
      xanchor: 'center',
      yanchor: 'bottom',
    },
    xaxis: {
      range: [-5, 105],
      showgrid: false,
    },
    yaxis: {
      gridwidth: 2,
      fixedrange: true,
    },
  };

  return (
    <Container maxHeight={'670px'} overflow="auto">
      <BDDPlotly data={pdata} layout={layout} />
    </Container>
  );
};

const ReportSkillsByScoutTable = ({ scoutToRankings }) => {
  const scoutIds = Object.keys(scoutToRankings);
  const values = Object.values(scoutToRankings);

  const ratings = values[0]
    .map((v) => v.rating)
    .filter(
      (rating, index, self) => index === self.findIndex((r) => r.slug === rating.slug)
    );

  const columns = [
    {
      id: 'scout',
      header: 'Scout',
      accessor: (id) => {
        return scoutToRankings[id][0].split.scoutName;
      },
    },
    {
      id: 'views',
      header: 'Views',
      accessor: (id) => scoutToRankings[id][0].count,
    },
    ...ratings.map((r) => ({
      id: r.slug,
      header: r.primaryName,
      accessor: (id) => {
        const percentile = scoutToRankings[id].find(
          (ranking) => ranking.rating.slug == r.slug
        )?.percentile;

        return percentile ? roundToX(100 * (1 - percentile), 1) : null;
      },
      renderCell: (id, value) => (
        <Container
          style={{ backgroundColor: value && getRedGreenColor(value, 0, 100, false, 2) }}
        >
          <Cell>{value || '-'}</Cell>
        </Container>
      ),
    })),
  ];

  const tableColumns = buildColumns(columns);

  return (
    <Container>
      <Typography variant="caption">
        Double click a scout's name to highlight their grades
      </Typography>
      <BDDSortableTable
        columnJustify="start"
        columns={tableColumns}
        data={scoutIds}
        defaultSort={[{ id: 'views', desc: true }]}
      />
    </Container>
  );
};
