import { Column, Container, Row } from 'components/bdd/Layout';
import { useSelect } from 'components/bdd/Select';
import useToggle from 'components/bdd/Toggle/useToggle';
import { Typography } from 'components/bdd/Typography';
import { useContext, useEffect } from 'react';
import { SLStatContext } from '..';

export const TrendChartOptions = ({}) => {
  const { data, setChartOptions, target, posFDG } = useContext(SLStatContext);

  const options = data?.format?.stat_definitions
    .filter((s) => s.slug != 'gamesPlayed')
    .map((s) => ({
      label: s.label,
      value: s.label,
    }));

  const { select: yAxisSelect, selected: yAxisStat } = useSelect({
    options: options || [],
  });

  const { toggled: invertYAxis, toggleComponent: invertYAxisToggle } =
    useToggle({ id: 'invert-y-axis', label: 'Invert' });

  const { toggled: showLeagueAvg, toggleComponent: leagueAvgToggle } =
    useToggle({
      label: 'Show League Avg',
      initialToggled: true,
    });

  const { toggled: showStandardRange, toggleComponent: standardRangeToggle } =
    useToggle({
      label: 'Show Standard Range',
      initialToggled: true,
    });

  const { toggled: compareToTeam, toggleComponent: targetGroupToggle } =
    useToggle({
      id: 'toggle-compare-team',
      label: 'Compare to team',
      initialToggled: posFDG === 'g' ? false : true,
      disabled:
        !data ||
        !data.data ||
        (data.data.length && !('team_slug' in data.data[0])),
    });

  // Toggle to control if we compare to "all" or specific position
  const { toggled: compareToPosition, toggleComponent: positionToggle } =
    useToggle({
      id: 'toggle-compare-position',
      label: `Compare to ${posFDG?.toUpperCase() || 'position'}`,
      initialToggled: true,
      disabled: posFDG === 'g' || target === 'teams',
    });

  const { selectedValue: windowSize, select: smoothSelect } = useSelect({
    initialSelectedValue: 9,
    options: [
      { label: 'No Smoothing', value: 1 },
      { label: 'Smooth Light', value: 3 },
      { label: 'Smooth Medium', value: 9 },
      { label: 'Smooth Heavy', value: 21 },
    ],
  });

  useEffect(() => {
    setChartOptions({
      type: 'trend',
      options: {
        yAxisStat: yAxisStat?.value,
        showLeagueAvg,
        showStandardRange,
        compareToTeam,
        compareToPosition,
        invertYAxis,
        windowSize,
      },
    });
  }, [
    yAxisStat?.value,
    showLeagueAvg,
    showStandardRange,
    invertYAxis,
    compareToTeam,
    compareToPosition,
    windowSize,
  ]);

  if (!data) {
    return <Typography variant="body1">Loading options...</Typography>;
  }

  return (
    <>
      <Row columnGap={2}>
        <Column>
          <Row justifyContent="space-between">
            <Typography variant="body1">Stat</Typography>
            {invertYAxisToggle}
          </Row>
          <Container width={150}>{yAxisSelect}</Container>
        </Column>
      </Row>
      <Row flexWrap columnGap={3}>
        {leagueAvgToggle}
        {standardRangeToggle}
        {target !== 'teams' && targetGroupToggle}
        {target !== 'teams' && positionToggle}
        <Container width={200}>{smoothSelect}</Container>
      </Row>
    </>
  );
};
