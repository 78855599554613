import React, { useState } from "react"
import DatePicker from "react-datepicker"
import styled from "styled-components"
import "react-datepicker/dist/react-datepicker.css"
import { formatDate } from "../../helpers/helpers"
import { theme } from "constants"

export const BDDDatepickerStyles = styled.div`
  .react-datepicker-popper {
    z-index: ${(props) => props.zIndex || 3};
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    width: 100%;
    display: block;
    height: ${(props) => (props.compact ? 'auto' : '38px')};
    border-radius: 5px;
    background-color: white;
    caret-color: transparent;
    font-family: Oswald;
  }

  .react-datepicker__input-container input {
    cursor: pointer;
    border: ${(props) => (props.noBorder ? 0 : '1px solid #ccc')};
    padding: ${(props) => (props.compact ? '5px' : '6px')};
    font-size: ${(props) => (props.compact ? '12px' : '14px')};
    color: ${theme.colors.light.text.secondary};
  }

  .react-datepicker__input-container input::placeholder {
    margin-left: 20px;
    color: #7f7f7f;
  }
`;

export default function BDDDatePicker({
  placeholderText='Pick a date',
  value,
  selected,
  onChange,

  noBorder,
  zIndex,
  compact,

  ...props
}) {
    return (
      <BDDDatepickerStyles
        noBorder={noBorder}
        zIndex={zIndex}
        compact={compact}
      >
        <DatePicker
          placeholderText={placeholderText}
          value={value}
          selected={selected}
          onChange={onChange}
          {...props}
        />
      </BDDDatepickerStyles>
    );
}

export function BDDDateRangePicker({startDate, endDate, onChange, zIndex, ...rest}) {
    const [shouldCloseNextSelect, setShouldCloseNextSelect] = useState(false)
    if (!!startDate && !!endDate) {
        const sdf = formatDate(startDate, {format: '%m/%d/%Y', useTimeZone: false})
        const edf = formatDate(endDate, {format: '%m/%d/%Y', useTimeZone: false})
        var value = `${sdf} - ${edf}`        
    } else {
        var value = 'Select Date Range'
    }
    const handleChange = dates => {
        if (!dates) { 
            onChange([null, null]) 
            return
        }

        if (!dates[0]) {
            setShouldCloseNextSelect(false)
        } else if (!dates[1]) {
            if (dates[0] != startDate && !!startDate && !endDate) {
                setShouldCloseNextSelect(false)
            } else {
                setShouldCloseNextSelect(true)
            }
        } else { // both have values
            setShouldCloseNextSelect(false)
        }
        onChange(dates)
    }

    const handleClose = () => {
        if (!startDate || !endDate) {
            onChange([null, null]) // clear out both dates if either is missing
        }
        setShouldCloseNextSelect(false)
    }

    return (
      <BDDDatepickerStyles {...rest} zIndex={zIndex}>
        <DatePicker
          placeholderText="Select Date Range"
          value={value}
          {...rest}
          selectsRange
          onChange={handleChange}
          startDate={startDate}
          endDate={endDate}
          shouldCloseOnSelect={shouldCloseNextSelect}
          onCalendarClose={handleClose}
          customInput={
            <div className="react-datepicker__input-container">
              <input value={value} onChange={() => {}} />
            </div>
          }
        />
      </BDDDatepickerStyles>
    );
}

