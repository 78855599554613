import {
  DRAFT_ROUTE,
  PLAYERS_V2_ROUTE,
  SCOUTING_ROUTE,
  TEAM_ROUTE,
  TEAM_SCENARIO_ROUTE,
  LISTS_ROUTE,
  POLLS_ROUTE,
  GAME_ROUTE,
  PRO_OBJECTIVE_ROUTE,
  SCHEDULE_ROUTE,
  COMPARE_PLAYERS_ROUTE,
  CALENDAR_ROUTE,
} from "../constants";
import { getCurrentSeason } from "./hockeyutils";

export function buildPlayerRoute(slug) {
  return `${PLAYERS_V2_ROUTE}/${slug}`;
}

export function buildPlayerRouteV2(slug) {
  return `${PLAYERS_V2_ROUTE}/${slug}`;
}

export function buildTeamRoute(slug, section = "") {
  return `${TEAM_ROUTE}/${slug}/${section}`;
}

export function buildTeamScheduleRoute(slug) {
  return `${TEAM_ROUTE}/${slug}/schedule`;
}

export function buildTeamSegmentRoute(slug, reportId) {
  return `${TEAM_ROUTE}/${slug}/segment/${reportId}`;
}

export function buildPostgameRoute(gameUid, teamSlug, section = "") {
  return `${GAME_ROUTE}/${gameUid}/postgame/${teamSlug}/${section}`;
}
export function buildPregameRoute(gameUid, teamSlug, section = "") {
  return `${GAME_ROUTE}/${gameUid}/pregame/${teamSlug}/${section}`;
}

export function buildInGameRoute(bddGameId, teamSlug, section = "") {
  return `${GAME_ROUTE}/bdd/${bddGameId}/ingame/${teamSlug}/${section}`;
}

export function buildBddPregameRoute(bddGameId, teamSlug, section = "") {
  return `${GAME_ROUTE}/bdd/${bddGameId}/pregame/${teamSlug}/${section}`;
}

export function buildNhlGameSummaryRoute(season, slGameUid) {
  if (!slGameUid) return null;
  return `${GAME_ROUTE}/nhlReport/season/${season}/game/${slGameUid.slice(8)}`;
}

export function buildNhlEventSummaryRoute(season, slGameUid) {
  if (!slGameUid) return null;
  return `${GAME_ROUTE}/nhlReport/season/${season}/event/${slGameUid.slice(8)}`;
}

export function buildBddGameCardRoute(bddGameId) {
  return `${GAME_ROUTE}/bdd/${bddGameId}`;
}

export function buildScheduleRoute({
  bddLeagueSlug = "nhl",
  season = getCurrentSeason(),
  slTeamSlugs = [],
}) {
  const searchParams = new URLSearchParams({
    target: 'leagueteam',
    league: bddLeagueSlug,
    season,
    slTeamSlugs: slTeamSlugs.join(','),
  });
  return `${CALENDAR_ROUTE}${SCHEDULE_ROUTE}?${searchParams}`;
}

export function buildEPLink(epid, firstname, lastname) {
  if (!epid || !firstname || !lastname) return null;
  const hyphen_name = `${firstname.toLowerCase().replace(' ', '-')}-${lastname
    .toLowerCase()
    .replace(' ', '-')}`;
  return `https://www.eliteprospects.com/player/${epid}/${hyphen_name}`;
}

export function buildDraftRoute(draftSlug) {
  return `${DRAFT_ROUTE}/app/${draftSlug}`;
}
export function buildDraftAdminRoute(draftSlug) {
  return `${DRAFT_ROUTE}/app/${draftSlug}/admin`;
}

export function buildPlayerListRoute(listType, id) {
  if (listType === 'DRAFT_LIST') {
    return `${LISTS_ROUTE}/draft/draftListBuilder/${id}`;
  } else if (listType === 'PRO_LIST') {
    return `${LISTS_ROUTE}/pro/proListBuilder/${id}`;
  } else if (listType === 'RINKNET') {
    return `${LISTS_ROUTE}/rinknet/rinknetListBuilder/${id}`;
  } else {
    return `${LISTS_ROUTE}/draft/draftListBuilder/${id}`;
  }
}

export function buildBddPollRoute(id, mode) {
  return `${POLLS_ROUTE}/${id}`;
  // return `${POLLS_ROUTE}?selectedPollId=${id}&selectedMode=${
  //   mode || 'my-response'
  // }`;
}

export function buildProObjectiveRoute(slug) {
  return `${PRO_OBJECTIVE_ROUTE}/${slug}`;
}

export function buildSingleQuestionnaireRoute(year, rinknetId) {
  return `${SCOUTING_ROUTE}/questionnaires/${year}/${rinknetId}`;
}

export function buildComparePlayersRoute(playerSlugs) {
  if (Array.isArray(playerSlugs)) {
    playerSlugs = playerSlugs.join(',');
  }
  return `${COMPARE_PLAYERS_ROUTE}?selectedPlayerSlugs=${playerSlugs || ''}`;
}


export function buildDraftYearRoute(draftYear) {
  return `/draft/drafthistory?year=${draftYear}`;
}