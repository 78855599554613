import { withProps } from '@udecode/cn';
import BDDDropdownMenu from 'components/bdd/bdddropdownmenu';
import { useBreakpoints } from 'components/bdd/context';
import { Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import BFPlayerContractsSmall from 'components/PlayersV2/Contract/BFPlayerContractsSmall';
import PlayerHover from 'components/PlayersV2/Hover/playerhover';
import BDDTag, {
  BDDTagTooltip,
  getTagBackgroundColor,
} from 'components/PlayerTagging/bddtag';
import { useState } from 'react';

export default function DCPlayerSmall({ playerSlug, bddPlayer, dropdownOptions }) {
  const [show, setShow] = useState(false);
  const { isMobile } = useBreakpoints();
  // dropdown menu must be controlled from here so we can disabled playerhover

  const content = (
    <Typography variant="body1" textAlign="center">
      <Row columnGap={1} alignItems="center" justifyContent="start">
        {bddPlayer.roleTag && (
          // <BDDTagTooltip
          //   tag={bddPlayer.roleTag.tag}
          //   isProjection={bddPlayer.roleTag.isProjection}
          // >
          //   <Container
          //     style={{
          //       width: 4,
          //       height: '100%',
          //       background: getTagBackgroundColor(
          //         bddPlayer.roleTag.tag.color,
          //         bddPlayer.roleTag.isProjection
          //       ),
          //     }}
          //   />
          // </BDDTagTooltip>
          <Container>
            <BDDTag
              tag={bddPlayer.roleTag.tag}
              isProjection={bddPlayer.roleTag.isProjection}
              noLabel
            />
          </Container>
        )}
        <Container>
          <PlayerHover
            disabled={show}
            playerSlug={bddPlayer.slug}
            delay={{ show: 800, hide: 50 }}
          >
            {isMobile ? '' : `${bddPlayer.firstname.slice(0, 1)}.`} {bddPlayer.lastname}
          </PlayerHover>
        </Container>
        <Container marginLeft={'auto'}>
          <BFPlayerContractsSmall
            name={`${bddPlayer.firstname} ${bddPlayer.lastname}`}
            bfPlayer={bddPlayer.bfPlayer}
            bfPlayerSlug={bddPlayer.bfPlayer?.slug}
            variant="stat"
            showNoContract={false}
          />
        </Container>
      </Row>
    </Typography>
  );

  if (!!dropdownOptions && dropdownOptions.length > 0) {
    return (
      <BDDDropdownMenu
        id={`${playerSlug}-dropdown`}
        show={show}
        onToggle={() => {
          setShow(!show);
        }}
        trigger={content}
        items={dropdownOptions}
      />
    );
  }

  return content;
}
