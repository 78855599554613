import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { theme, PLAYERS_V2_ROUTE } from 'constants';
import { getNHLTeamLogoUrl } from 'helpers/logos';
import PlayerHover from 'components/PlayersV2/Hover/playerhover';
import { roundToX } from 'helpers/data';
import { WindowContext } from 'components/bdd/context';

import { IconButton, NoColorLink } from 'components/bdd/Button';
import {
  CameraVideo,
  JournalBookmark,
  QuestionSquare,
  Stickies,
} from 'react-bootstrap-icons';
import { Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { buildPlayerRoute, buildTeamRoute } from 'helpers/routing';
import { HoverInteractive } from 'components/bdd';
import { BDDPlayerNoteViewer, PLPlayerNotes } from './ListPlayerComponents';
import NotificationIndicator, {
  NotificationWrapper,
} from 'components/bdd/NotificationIndicator';
import { faSkating } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BFPlayerContractsSmall from 'components/PlayersV2/Contract/BFPlayerContractsSmall';
import TruncatedText from 'components/bdd/Truncated/TruncatedText';
import { TooltipSpan } from 'components/reports';
import { PlayerHeightWeight } from 'components/PlayersV2/PlayerSize/PlayerHeightWeight';

const NameContainer = styled.div(({ minWidth }) => ({
  minWidth,
}));

const HoverContainer = styled.div(({ center }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: center ? 'center' : 'start',
  gap: theme.spacing[2],
}));

const Name = styled.div(({ variant, center }) => ({
  ...theme.buttons.link(theme.colors.light.text.primary),
  ...theme.typography[variant],
  alignItems: 'center',
  justifyContent: 'start',
}));

const BodyContainer = styled.div({
  ...theme.typography.body2,
  minWidth: 200,
});

export const PlayerName = ({
  rp,
  bdd,
  slp,
  showPlayerNotesIcon = true,
  showVideoIcon = true,
  playerToCombineNotes = {},
  hasQuestionnaire,
  hasSkatingEval,
  questionnaireModal,
  bddPlayerNotes,
  createNote,
  updateNote,
  overridePlayerLink,
  setShowAddNoteModal,
  variant = 'subtitle1',
  allowHover = true,
}) => {
  const { width } = useContext(WindowContext);
  const { pathname, search } = useLocation();
  const history = useHistory();
  const isMobile = width < theme.breakpoints.sm;

  const showDraftPlayerModal = (playerId, focus) => {
    history.push(
      `${pathname}${search}#player=${playerId}${`&focus=${focus || 'scoutingreports'}`}`
    );
  };

  const title = (
    <>
      {rp.currentteamnhlid && (
        <img src={getNHLTeamLogoUrl(rp.currentteamnhlid)} height="20px" />
      )}
      {rp.firstname} {rp.lastname}
    </>
  );

  return (
    <Container minWidth={250}>
      <Row columnGap={2}>
        <PlayerHover
          playerSlug={bdd?.slug}
          rinknetId={rp.realId}
          disabled={!allowHover || isMobile}
        >
          {bdd?.slug ? (
            <Name
              variant={variant}
              as={Link}
              onContextMenu={(ev) => ev.stopPropagation()}
              to={
                overridePlayerLink
                  ? overridePlayerLink
                  : `${PLAYERS_V2_ROUTE}/${bdd?.slug}`
              }
            >
              {title}
            </Name>
          ) : (
            <Name variant={variant} onClick={() => showDraftPlayerModal(rp.realId)}>
              {title}
            </Name>
          )}
        </PlayerHover>
        {rp.realId in playerToCombineNotes && (
          <IconButton
            icon={<JournalBookmark />}
            tooltip="Combine Notes"
            onClick={(event) => {
              event.stopPropagation();
              showDraftPlayerModal(rp.realId, 'combinenotes');
            }}
            hoverColor={theme.colors.teams.bos.primary}
          />
        )}
        {hasQuestionnaire && (
          <IconButton
            tooltip="Questionnaire"
            icon={<QuestionSquare />}
            onClick={(event) => {
              event.stopPropagation();
              showDraftPlayerModal(rp.realId, 'questionnaire');
            }}
            hoverColor={theme.colors.teams.bos.primary}
          />
        )}
        {hasSkatingEval && (
          <IconButton
            tooltip="Skating Eval"
            icon={<FontAwesomeIcon icon={faSkating} />}
            onClick={(event) => {
              event.stopPropagation();
              showDraftPlayerModal(rp.realId, 'skatingeval');
            }}
            hoverColor={theme.colors.teams.bos.primary}
          />
        )}
        {!!bddPlayerNotes && showPlayerNotesIcon && bddPlayerNotes?.length > 0 && (
          <HoverInteractive
            renderAsFullScreenMobile
            renderContent={(forceClose) => (
              <div
                style={{
                  maxWidth: '400px',
                  maxHeight: '800px',
                  overflow: 'auto',
                  paddingRight: '8px',
                }}
              >
                <BDDPlayerNoteViewer
                  rinknetPlayer={rp}
                  bddPlayer={bdd}
                  createNote={createNote}
                  updateNote={updateNote}
                  playerNoteItems={bddPlayerNotes}
                  shouldRender={true}
                  setShowAddNoteModal={(setShow) => {
                    forceClose(true);
                    setShowAddNoteModal(setShow);
                  }}
                />
              </div>
            )}
          >
            <IconButton
              icon={
                <NotificationWrapper>
                  <Stickies />
                  <NotificationIndicator>{bddPlayerNotes.length}</NotificationIndicator>
                </NotificationWrapper>
              }
              onClick={(event) => {
                event.stopPropagation();
              }}
              hoverColor={theme.colors.teams.bos.primary}
            />
          </HoverInteractive>
        )}
        {!!showVideoIcon && !!bdd && (
          <Link target="_blank" to={`${buildPlayerRoute(bdd?.slug)}/video`}>
            <IconButton
              tooltip="Video"
              icon={<CameraVideo />}
              onClick={(event) => {
                event.stopPropagation();
              }}
              hoverColor={theme.colors.teams.bos.primary}
            />
          </Link>
        )}
      </Row>
    </Container>
  );
};

export const PlayerActiveContract = ({ bd }) => {
  if (!bd) return null;
  if (!bd.bfPlayer) return null;

  return (
    <BFPlayerContractsSmall
      name={`${bd.firstname} ${bd.lastname}`}
      bfPlayer={bd.bfPlayer}
      bfPlayerSlug={bd.bfSlug}
    />
  );
};

export const PlayerLeague = ({ rp, teamSlug }) => {
  const tSlug = teamSlug || rp.currentteamslug;
  return (
    <Typography variant="body2">
      <Row columnGap={1}>
        {!!tSlug ? (
          <NoColorLink
            textDecoration="underline"
            as={Link}
            to={buildTeamRoute(tSlug)}
          >
            {rp.currentteam}
          </NoColorLink>
        ) : rp.currentteam ? (
          <Row columnGap={1}>
            <TruncatedText showTooltip maxCharacters={20}>
              {rp.currentteam}
            </TruncatedText>{' '}
            | {rp.currentleagueabbr}
          </Row>
        ) : (
          <span>Unsigned</span>
        )}
      </Row>
    </Typography>
  );
};

export const PlayerTeam = ({ rp }) => {
  return (
    <Typography variant="body2">
      <TooltipSpan
        content={
          <Typography variant="body2">
            {rp.currentteam} | {rp.currentleagueabbr}
          </Typography>
        }
      >
        {!!rp.currentteamslug ? (
          <Link to={buildTeamRoute(rp.currentteamslug)}>{rp.currentteam}</Link>
        ) : (
          <TruncatedText maxCharacters={15}>{rp.currentteam}</TruncatedText>
        )}
      </TooltipSpan>
    </Typography>
  );
};

export const PlayerAge = ({ rp }) => {
  return (
    <TooltipSpan content={<Typography variant="body2">{rp.birthdate}</Typography>}>
      {Math.floor(rp.age)} y.o.
    </TooltipSpan>
  );
};

export const PlayerInfo = ({ rp, rinknetIds }) => {
  return (
    <Typography variant="body2">
      <Row columnGap={1} flexWrap>
        <PlayerHeightWeight rinknetPlayer={rp} rinknetIds={rinknetIds} />
        <div>|</div> <PlayerTeam rp={rp} />| <PlayerAge rp={rp} />
      </Row>
    </Typography>
  );
};

export const PlayerInfoRow = ({ rp, bd, rinknetIds }) => {
  const hasContract = !!bd?.activeContract;
  return (
    <Typography variant="body2">
      <Row columnGap={1} flexWrap>
        {hasContract && (
          <span>
            <b>{bd.activeContract.averagedAmountStr}</b> x
            {bd.activeContract.remainingTerm}
          </span>
        )}
        {hasContract && <div>|</div>}
        <PlayerHeightWeight rinknetPlayer={rp} rinknetIds={rinknetIds} />
        {!hasContract && <div>|</div>}
        {!hasContract && rp.birthdate} | {roundToX(rp.age, 1)} y.o.
      </Row>
    </Typography>
  );
};

export const positionToShort = {
  'Left Wing': 'LW',
  'Right Wing': 'RW',
  Center: 'C',
  'Left Defence': 'LD',
  'Right Defence': 'RD',
  Goalie: 'G',
  "Center/R.Wing": 'C/RW',
  "Defence": 'D',
  "Center/L.Wing": 'C/LW',
  "Wing": 'W'
};

export const PlayerPosition = ({ rp, useShortPosition }) => {
  return (
    <Typography variant="body2">
      {rp.handedness} | {useShortPosition ? positionToShort[rp.position] : rp.position}
    </Typography>
  );
};

export const PlayerDraftYear = ({ rp }) => {
  return <Typography variant="body2">{rp.draftyear}</Typography>;
};
