import React, { useState } from 'react';
import styled from 'styled-components';
import { theme } from 'constants';
import BDDErrorBoundary from '../bdderrorboundary';
import { Column, Container, Row } from '../Layout';
import { Divider } from '../Divider';
import { IconButton } from '../Button';
import { Modal } from './Modal';
import { XLg } from 'react-bootstrap-icons';
import { Modal as BootstrapModal } from 'react-bootstrap';

const ModalTitle = styled.div({
  ...theme.typography.h5,
  color: theme.colors.light.text.secondary,
});

export const XlModal = styled.div({
  width: '80%',
  marginTop: theme.spacing[4],
  marginLeft: 'auto',
  marginRight: 'auto',
  backgroundColor: theme.colors.light.background,
});

export const XXlModal = styled.div({
  width: '90%',
  marginTop: theme.spacing[4],
  marginLeft: 'auto',
  marginRight: 'auto',
  backgroundColor: theme.colors.light.background,
});

const useModal = ({
  title,
  body,
  footer,
  children,
  size,
  onHide,
  useNewModal,
  emptyTitle,
}) => {
  const [showModal, setShowModal] = useState(false);
  const hideModal = () => {
    setShowModal(false);
    !!onHide && onHide();
  };

  const renderModal = (body) =>
    useNewModal ? (
      <Modal size={size || 'lg'} show={showModal} onHide={hideModal}>
        <BDDErrorBoundary>
          <Container height="100%">
            <Column gap={2}>
              {(title || emptyTitle) && (
                <Container>
                  <Column>
                    <Container padding={3}>
                      <Row justifyContent="space-between">
                        {title}
                        {emptyTitle && <div />}
                        <IconButton
                          tooltip={'Exit modal'}
                          onClick={(ev) => {
                            console.log('exiting');
                            ev.stopPropagation();
                            hideModal();
                          }}
                          icon={<XLg />}
                        />
                      </Row>
                    </Container>
                    <Divider />
                  </Column>
                </Container>
              )}
              <Container height="100%" overflow="auto">
                {body}
                {children}
              </Container>
              {footer && (
                <Container>
                  <Column>
                    <Divider />
                    <Container width="100%" padding={3}>
                      <Row justifyContent="end">{footer}</Row>
                    </Container>
                  </Column>
                </Container>
              )}
            </Column>
          </Container>
        </BDDErrorBoundary>
      </Modal>
    ) : (
      <div onClick={(ev) => ev.stopPropagation()}>
        <BootstrapModal
          size={size || 'lg'}
          show={showModal}
          onHide={hideModal}
          {...(size == 'xl' ? { dialogAs: XlModal } : {})}
          {...(size == 'xxl' ? { dialogAs: XXlModal } : {})}
        >
          {title && (
            <BootstrapModal.Header closeButton>
              <ModalTitle>{title}</ModalTitle>
            </BootstrapModal.Header>
          )}
          <BootstrapModal.Body>
            <BDDErrorBoundary>
              {body}
              {children}
            </BDDErrorBoundary>
          </BootstrapModal.Body>
        </BootstrapModal>
      </div>
    );

  return {
    showModal,
    setShowModal,
    modal: renderModal(body),
    renderModal,
  };
};

export default useModal;
