import localforage from 'localforage';
import { buildSearchParams, parseSearchParams, SearchParams } from './searchParams';
import { createContext, useContext, useEffect, useRef } from 'react';
import { immer } from 'zustand/middleware/immer';
import { create, useStore as useZustandStore } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export function defaultSet(set, key) {
  return (data) =>
    set((state) => {
      state[key] = typeof data === 'function' ? data(state[key]) : data;
    });
}

export function buildSetActions(set, state) {
  return Object.keys(state).reduce((actions, key) => {
    const actionKey = `set${key.charAt(0).toUpperCase() + key.slice(1)}`;
    actions[actionKey] = defaultSet(set, key);

    return actions;
  }, {});
}

export function buildSearchParamStorage({ ignoreKeys = [] } = {}) {
  return {
    getItem: async (key) => {
      const searchParams = new SearchParams();
      const searchParamsState = searchParams.getSerialized(key);
      const persistItem = JSON.parse(await localforage.getItem(key));
      const persistState = persistItem['state'];

      return JSON.stringify({
        ...persistItem,
        state: {
          ...persistState,
          ...searchParamsState,
        },
      });
    },
    setItem: async (key, value) => {
      const searchParams = new SearchParams();
      const searchParamsState = searchParams.getSerialized(key);
      let state = JSON.parse(value)['state'];

      Object.keys(state).forEach((stateKey) => {
        if (ignoreKeys.includes(stateKey)) {
          delete state[stateKey];
        }
      });

      const hasChanged = JSON.stringify(searchParamsState) != JSON.stringify(state);
      if (hasChanged) {
        searchParams.setSerialized(key, state);
        searchParams.push();
        await localforage.setItem(key, value);
      }
    },
    removeItem: async (name) => {
      await localforage.removeItem(name);
    },
  };
}

function defaultPartialize(state) {
  const { actions, ...rest } = state;
  return rest;
}

function defaultOnRehydrateStorage(state) {
  return (state, error) => {
    if (error) {
      console.error('an error happened during hydration', error);
    }
  };
}

export const buildStore = (initialState) => {
  const createStore = (props, searchParamsState, buildActions, persistStore) => {
    let store = immer((set, get) => ({
      ...initialState,
      ...props,
      ...searchParamsState,
      actions: {
        ...buildSetActions(set, initialState),
        ...buildActions?.(set, initialState),
      },
    }));

    if (!!persistStore) {
      const { persistKey, partialize, onRehydrateStorage } = persistStore;

      store = persist(store, {
        name: persistKey,
        storage: createJSONStorage(() => localforage),
        partialize: partialize || defaultPartialize,
        onRehydrateStorage: onRehydrateStorage || defaultOnRehydrateStorage,
      });
    }

    return create(store);
  };

  const Context = createContext();

  const useStore = (selector) => {
    const context = useContext(Context);
    if (!context) return null;

    return useZustandStore(context, selector);
  };

  const SearchParamsListener = ({ useSearchParams }) => {
    const partialize = useSearchParams.partialize || defaultPartialize;
    const state = useStore(partialize);

    useEffect(() => {
      if (!useSearchParams) return;
      buildSearchParams(state, useSearchParams.key);
    }, [JSON.stringify(state)]);
  };

  const ContextProvider = ({
    children,
    buildActions,
    persist,
    useSearchParams,
    ...props
  }) => {
    const searchParamsState = useSearchParams
      ? parseSearchParams(useSearchParams.key) || {}
      : {};

    const store = useRef(
      createStore(props, searchParamsState, buildActions, persist)
    ).current;

    return (
      <Context.Provider value={store}>
        {useSearchParams && <SearchParamsListener useSearchParams={useSearchParams} />}
        {children}
      </Context.Provider>
    );
  };

  return {
    Context,
    ContextProvider,
    useStore,
    withContext: (contextProps, Component, props = {}) => {
      return (
        <ContextProvider {...contextProps}>
          <Component {...contextProps} {...props} />
        </ContextProvider>
      );
    },
  };
};
