import React, { useState } from 'react';
import styled from 'styled-components';

import { nameToLastName } from '../../helpers/hockeyutils';
import { strContains, roundToX } from '../../helpers/data';
import { reportStateVar } from '../../apollo/client';
import { HideableSelect, Unprinted } from './report.elements';
import BDDSortableTable from '../bdd/bddsortabletable';
import { sortNumeric } from '../../helpers/tables';
import {
  orderGroupPlayers,
  slugsStringToArr,
  slugsToString,
  slUidsToString,
} from '../../helpers/groups';
import { HoverInteractive } from '../bdd';
import { formatBddStat } from 'helpers/format';

export const StyledGroupTable = styled.div`
  table {
    width: 100%;
    text-align: center;

    th {
      border-right: 1px solid #ddd;
    }
    tr {
      border-bottom: 1px solid #dddddd77;
    }
  }
`;

function GroupTable({
  data,
  stats, // legacy arg, ignored and overwritten by statDefinitions
  statDefinitions,
  groupType,
  groupTitle,
  selectedGroups, // array of objects with keys: name, slUids/slugs (stringified)
  searchBySlug = false,
  showExpectedMatchup,
  expectedMatchups = {},
  handleSetExpectedMatchup,
  adminMode,
  defaultSort = [{ id: 'toi', desc: true }],
  playerToPosition = {}, // obj mapping slUids to pos (leftwing, leftdefence...) for guessing of order
  renderGroupHover,
  minToi,
  showMinToi=true,
  highlightCallback,
  cellHoverCallback,
  renderSubRow,
}) {
  if (!data || !data.length) return <>No Data</>;

  const statLabels = statDefinitions.map((s) => s.label);

  const groupTypeToSize = { pairs: 2, lines: 3, units: 5 };
  const groupTypeToNumber = { pairs: 3, lines: 4, units: 2 };
  const indexKeys = [];
  for (let i = 0; i < groupTypeToSize[groupType]; i++) {
    indexKeys.push(`Player${i}`);
  }

  const labelToDescription = {};
  const labelToStatDef = {};
  if (!!statDefinitions)
    statDefinitions.forEach((s) => {
      labelToDescription[s.label] = s.description;
      labelToStatDef[s.label] = s;
    });

  const formatStat = (statLabel, value) => {
    if (statLabel == '5v5 Points') {
      if (isNaN(value)) return '';
      return parseInt(value);
    } else if (statLabel == 'toi' && isNaN(value)) {
      return (
        <em>
          {'<'}
          {minToi} min.
        </em>
      );
    } else {
      if (isNaN(value)) return '';
      // return roundToX(value, 2)
      return formatBddStat(value, labelToStatDef[statLabel]);
    }
  };

  var primaryGroupData = null;
  var secondaryGroupData = [];
  if (selectedGroups && selectedGroups.length) {
    // if "selectedGroups" are supplied, we loop through these and build our primaryGroupData
    // this compares the sl_uid arrays with those constructed from the lineup queries
    // we also expect selectedGroups to include a "name" field for the group
    primaryGroupData = [];
    const groupStrings = [];
    selectedGroups.forEach((g) => {
      if (searchBySlug) {
        groupStrings.push(g.slugs);
      } else {
        groupStrings.push(g.slUids);
      }
      const dataRow = data.filter((d) => {
        if (searchBySlug) return slugsToString(d.slugs) === g.slugs;
        return slUidsToString(d.sl_uids) === g.slUids;
      });
      if (dataRow.length) {
        primaryGroupData.push({ ...dataRow[0], colName: g.name });
      } else {
        if (searchBySlug)
          primaryGroupData.push({
            colName: g.name,
            slugs: slugsStringToArr(g.slugs),
          });
        else primaryGroupData.push({ colName: g.name, slugs: g.slUids });
      }
    });
    data.forEach((d) => {
      const dataGroupString = searchBySlug
        ? slugsToString(d.slugs)
        : slUidsToString(d.sl_uids);
      if (!groupStrings.includes(dataGroupString)) {
        secondaryGroupData.push({
          ...d,
          colName: d.Players.map(nameToLastName).join('-'),
        });
      }
    });
  } else {
    secondaryGroupData = data.map((d) => ({
      ...d,
      colName: d.Players.map(nameToLastName).join('-'),
    }));
  }
  // For each of our secondary groups, see if we can rename them based on positions
  if (!!playerToPosition) {
    secondaryGroupData = secondaryGroupData.map((d) => {
      // const names = indexKeys.map(k => d[k])
      const positions = d.sl_uids.map((slUid) => playerToPosition[slUid]);
      if (!positions[0]) return d;
      const orderedPlayerIdx = orderGroupPlayers(groupType, positions);
      // we used to have "Player0", "Player1"...
      // const colName = orderedPlayerIdx.map(idx => nameToLastName(d[`Player${idx}`])).join('-')
      const colName = orderedPlayerIdx
        .map((idx) => nameToLastName(d.Players[idx]))
        .join('-');

      return { ...d, colName: colName };
    });
  }

  const matchupOptions = showExpectedMatchup
    ? [
        { label: 'Unknown', value: 'unk' },
        { label: '1st Line', value: '1' },
        { label: '2nd Line', value: '2' },
        { label: '3rd Line', value: '3' },
        { label: '4th Line', value: '4' },
      ]
    : null;

  const groupTitleColumn = {
    Header: groupTitle,
    accessor: (d) => {
      const content =
        'colName' in d
          ? d.colName
          : 'Players' in d
          ? Array.isArray(d.Players)
            ? d.Players.map(nameToLastName).join('-')
            : d.Players
          : indexKeys.map((p) => nameToLastName(d[p])).join('-');
      if (renderGroupHover) {
        return (
          <HoverInteractive
            title={content}
            renderContent={() => renderGroupHover(d)}
            closeButton
          >
            {content}
          </HoverInteractive>
        );
      }
      return content;
    },
  };
  const primaryColumns = [groupTitleColumn];
  const secondaryColumns = [groupTitleColumn];
  if (showExpectedMatchup)
    primaryColumns.push({
      Header: 'Exp. Matchup',
      accessor: (d) => (
        <HideableSelect
          disabled={!adminMode}
          hideSelect={!adminMode}
          value={
            'slugs' in d && slugsToString(d.slugs) in expectedMatchups
              ? expectedMatchups[slugsToString(d.slugs)]
              : 'unk'
          }
          onChange={(ev) =>
            handleSetExpectedMatchup(slugsToString(d.slugs), ev.target.value)
          }
        >
          {matchupOptions.map((o) => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </HideableSelect>
      ),
    });
  statLabels.forEach((s) => {
    const newCol = {
      Header: s,
      accessor: (d) => (
        <div
          style={{
            width: '100%',
            background: highlightCallback
              ? highlightCallback(s, d[s], d)
              : null,
          }}
        >
          {cellHoverCallback ? (
            <HoverInteractive
              renderContent={(forceClose) =>
                cellHoverCallback(s, d, labelToStatDef[s], forceClose)
              }
            >
              {formatStat(s, d[s])}
            </HoverInteractive>
          ) : (
            formatStat(s, d[s])
          )}
        </div>
      ),
      tooltip:
        !!statDefinitions && s in labelToDescription
          ? labelToDescription[s]
          : null,
      sortType: sortNumeric,
      sortDescFirst: true,
    };
    primaryColumns.push(newCol);
    secondaryColumns.push(newCol);
  });

  return (
    <div style={{ paddingBottom: '10px' }}>
      {primaryGroupData ? (
        <>
          <StyledGroupTable>
            <BDDSortableTable
              data={primaryGroupData}
              columns={primaryColumns}
            />
          </StyledGroupTable>
          <br></br>
        </>
      ) : null}
      {secondaryGroupData.length > 0 ? (
        <>
          <StyledGroupTable>
            <BDDSortableTable
              data={secondaryGroupData}
              columns={secondaryColumns}
              defaultSort={defaultSort}
              renderSubRow={renderSubRow}
            />
          </StyledGroupTable>
          {showMinToi && <small style={{ float: 'right' }}>
            <em>
              Showing {groupTitle} with {'>'} {minToi} min.
            </em>
          </small>}
        </>
      ) : null}
    </div>
  );
}

export { GroupTable };
