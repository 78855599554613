import React, { useEffect } from 'react';
import {
  useTable,
  useSortBy,
  usePagination,
  useExpanded,
  useFlexLayout,
} from 'react-table';
import BDDPagination from './bddpagination';
import { TooltipSpan } from '../reports';
import { Column, Container, Row } from './Layout';
import { useOnScreenRef } from './hooks';
import { SortDown, SortUp } from 'react-bootstrap-icons';
import Icon from './Icon';
import { Typography } from './Typography';
import { Cell } from './Table';
import { theme } from 'constants';
import { BDDLoader } from './bddloader';
import styled from 'styled-components';

const loadingWrapper = (
  <tbody>
    <tr>
      <td colSpan="100%">
        <BDDLoader variant="squares" />
      </td>
    </tr>
  </tbody>
);

function Table({
  columns,
  data,
  defaultSort,
  defaultSortDesc,
  disableSortRemove,
  manualSortBy = false,
  defaultPageLenArg,
  pagination,
  style,
  hasFooter,
  onSortChange,
  trClassCallback,
  flexLayout,
  virtualizeRows,
  columnJustify = 'center',
  leaveSpaceForSort = false,
  onRowClick,
  hiddenColumns = [],
  loading,
  renderSubRow,
}) {
  const defaultPageLen =
    defaultPageLenArg || (pagination && !defaultPageLenArg ? 20 : 999);

  const tableHooks = [useSortBy, useExpanded, usePagination];
  if (flexLayout) tableHooks.push(useFlexLayout);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy, ...otherstate },
    setHiddenColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: defaultSort,
        pageSize: defaultPageLen,
        hiddenColumns,
      },
      manualSortBy: manualSortBy,
      defaultSortDesc: defaultSortDesc,
      disableSortRemove: disableSortRemove,
    },
    ...tableHooks
  );

  useEffect(() => {
    if (onSortChange) {
      onSortChange(sortBy);
    }
  }, [JSON.stringify(sortBy)]);

  useEffect(() => {
    setHiddenColumns(hiddenColumns);
  }, [JSON.stringify(hiddenColumns)]);

  return (
    <>
      <table {...getTableProps()} style={style}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => {
                const headerProps = column.disableSort
                  ? column.getHeaderProps()
                  : column.getHeaderProps(column.getSortByToggleProps());
                let headerStyles = {
                  ...headerProps.style,
                  ...column.style,
                  ...(!flexLayout ? { minWidth: column.minWidth } : {}),
                };

                return (
                  // Add the sorting props to control sorting
                  <th {...headerProps} style={headerStyles}>
                    <Row columnGap={1} justifyContent={column.justify || columnJustify}>
                      <Container
                        style={
                          {
                            // marginLeft: columnJustify == 'center' ? '18px' : null,
                          }
                        }
                      >
                        {column.tooltip ? (
                          <TooltipSpan content={column.tooltip}>
                            {column.render('Header')}
                          </TooltipSpan>
                        ) : (
                          column.render('Header')
                        )}
                      </Container>

                      {/* Add a sort direction indicator */}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <Typography variant="h6">
                            <Icon icon={<SortDown />} />
                          </Typography>
                        ) : (
                          <Typography variant="h6">
                            <Icon icon={<SortUp />} />
                          </Typography>
                        )
                      ) : leaveSpaceForSort ? (
                        <Container width={18} />
                      ) : null}
                    </Row>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        {loading ? (
          loadingWrapper
        ) : (
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => (
              <TableRow
                key={`tr-${i}`}
                index={i}
                row={row}
                virtualizeRows={virtualizeRows}
                prepareRow={prepareRow}
                trClassCallback={trClassCallback}
                onRowClick={onRowClick}
                renderSubRow={renderSubRow}
              />
            ))}
          </tbody>
        )}
        {hasFooter && (
          <tfoot>
            {footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
      {pagination ? (
        <BDDPagination
          currPage={pageIndex + 1}
          setCurrPage={(pageNum) => gotoPage(pageNum - 1)}
          pageLen={pageSize}
          setPageLen={setPageSize}
          totalItems={data.length}
          itemName="rows"
          pageLenOptions={[10, 20, 35, 50, 100]}
        />
      ) : null}
    </>
  );
}

const TableRow = ({
  prepareRow,
  index,
  row,
  trClassCallback,
  virtualizeRows,
  onRowClick,
  renderSubRow,
}) => {
  const { ref, isVisible } = useOnScreenRef({ resetVariable: row });
  prepareRow(row);

  const expandedRowProps = row.canExpand ? row.getToggleRowExpandedProps() : {};
  const combinedStyles = {
    ...row.getRowProps().style,
    ...(expandedRowProps.style || {}),
  };

  // getting rid of a complaint about non-boolean prop "z"
  const parseCellProps = (cell) => {
    const cellProps = cell.getCellProps();
    const ret = {};
    Object.keys(cellProps).forEach((key) => {
      if (key === 'z') ret[key] = cellProps[k].toString();
      else ret[key] = cellProps[key];
    });
    return ret;
  };

  return (
    <>
      <tr
        ref={virtualizeRows ? ref : null}
        {...row.getRowProps()}
        style={combinedStyles}
        onClick={(e) => {
          expandedRowProps.onClick && expandedRowProps.onClick(e);
          !!onRowClick && onRowClick(row);
        }}
        className={!!trClassCallback ? trClassCallback(row) : ''}
      >
        {row.cells.map((cell) => {
          if (cell.column.id == 'index') {
            return (
              <td {...cell.getCellProps()}>
                <Cell textAlign="center">{index + 1}</Cell>
              </td>
            );
          }
          return (
            <td {...parseCellProps(cell)}>
              {(isVisible || !virtualizeRows || cell.column.alwaysVisible) &&
                cell.render('Cell')}
            </td>
          );
        })}
      </tr>
      {renderSubRow && (
        <tr>
          <td colSpan={row.cells.length}>{renderSubRow(row)}</td>
        </tr>
      )}
    </>
  );
};

export default function BDDSortableTable({
  columns: colArg,
  data: dataArg,
  defaultSort: defaultSortArg,
  manualSortBy = false, // if supplied, can change teh defaultSort to manage sorting externally
  onSortChange, // required if manualSortBy
  pagination = false,
  defaultPageLenArg,
  style,
  hasFooter = false,
  defaultSortDesc = false,
  disableSortRemove,
  scroll = true,
  maxHeight,
  trClassCallback,
  flexLayout,
  onRowClick,
  virtualizeRows,
  columnJustify = 'center',
  leaveSpaceForSort = false,
  hiddenColumns,
  loading,
  renderSubRow,
}) {
  /* 
    columns: [
        { Header: 'First Name', accessor: 'firstname' },
        { Header: 'Last Name', accessor: d => d.lastname },
    defaultSort: [{ id: 'toi', desc: true }]
    ] */

  const columns = React.useMemo(() => colArg, [colArg]);
  const data = React.useMemo(() => dataArg, [dataArg]);
  const defaultSort = React.useMemo(
    () => (!!defaultSortArg ? defaultSortArg : []),
    [defaultSortArg]
  );
  if (!style) style = { width: '100%' };

  return (
    <div
      style={{
        ...(scroll ? { overflow: data?.length > 0 ? 'auto' : 'hidden', maxHeight } : {}),
      }}
    >
      <Table
        columns={columns}
        data={data}
        defaultSort={defaultSort}
        defaultSortDesc={defaultSortDesc}
        disableSortRemove={disableSortRemove}
        manualSortBy={manualSortBy}
        onSortChange={onSortChange}
        pagination={pagination}
        defaultPageLenArg={defaultPageLenArg}
        style={style}
        hasFooter={hasFooter}
        trClassCallback={trClassCallback}
        flexLayout={flexLayout}
        onRowClick={onRowClick}
        virtualizeRows={virtualizeRows}
        columnJustify={columnJustify}
        leaveSpaceForSort={leaveSpaceForSort}
        hiddenColumns={hiddenColumns}
        loading={loading}
        renderSubRow={renderSubRow}
      />
    </div>
  );
}

const Styles = styled.div(({ striped, hover }) => ({
  table: {
    width: '100%',
    th: {
      ...theme.typography.body1,
    },
    thead: {
      'tr:last-child': {
        th: {
          borderBottom: '1px solid #aaa',
        },
      },
    },
    td: {
      ...theme.typography.body2,
    },
    ...(striped
      ? {
          'tr:nth-child(even)': {
            td: {
              backgroundColor: '#f2f2f2',
            },
          },
        }
      : {}),
  },
}));

export const StyledBDDSortableTable = (
  props,
  striped = false,
  highlightHoverRow = false
) => {
  return (
    <Styles striped={striped} hover={highlightHoverRow}>
      <BDDSortableTable {...props} />
    </Styles>
  );
};
